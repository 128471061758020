import React, { useState, useEffect, useContext, useRef } from 'react';
import { Grid } from '@mui/material';

import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../assets/css/buttons.css';

import { SocketContext } from '../../../context/SocketContext';
import { SocketNFCContext } from '../../../context/SocketNFC';
import toast, { Toaster } from 'react-hot-toast';

import LastUsers from './LastUsers';

import ModalAnonUserRegistration from '../UserModals/ModalAnonUserRegistration';
import ModalHybridUserRegistration from '../UserModals/ModalHybridUserRegistration';
import ModalHybridUserEdit from '../UserModals/ModalHybridUserEdit';
import ModalFullUserRegistration from '../UserModals/ModalFullUserRegistration';
import ModalFullUserEdit from '../UserModals/ModalFullUserEdit';
import ModalCashoutPending from '../UserModals/ModalCashoutPending';
import ModalUserInfo from '../UserModals/ModalUserInfo';
import ModalKiosk from '../Kiosk/ModalKiosk';

import CardPayment from '../PaymentScreen/CardPayment';
import ModalSpillerkonto from '../UserModals/ModalSpillerkonto';

import LastUsersFiltered from './LastUsersFiltered';

import HeaderSearcher from './HeaderSearcher';
import HeaderUserDescription from './HeaderUserDescription';
import TotalButton from './TotalButton';
import TransactionList from './TransactionList';

import ModalUserFound from './ModalUserFound';
import ModalUserNotFound from './ModalUserNotFound';
import ModalOpenSession from '../UserModals/ModalOpenSession';
import ModalEditUserLimits from '../UserModals/ModalEditUserLimits';
import ModalCardToCash from '../UserModals/ModalCardToCash';
import ModalIsLimitSurpassed from '../UserModals/ModalIsLimitSurpassed';
import ModalIsVerificationNeeded from '../UserModals/ModalIsVerificationNeeded';
import ModalIsLimitSurpassedTransaction from '../UserModals/ModalIsLimitSurpassedTransaction';
import ModalDifferentParent from '../UserModals/ModalDifferentParent';
import ModalUserInfoSibling from '../UserModals/ModalUserInfoSibling';
import ModalPictureVerification from '../UserModals/ModalPictureVerification';
import ModalPictureVerificationWarning from '../UserModals/ModalPictureVerificationWarning';
import ModalProfileImage from '../UserModals/ModalProfileImage';
import ModalPictureVerificationPreRegister from '../UserModals/ModalPictureVerificationPreRegister';
import ModalUserInfoOK from '../UserModals/ModalUserInfoOK';
import ModalUserInfoWG from '../UserModals/ModalUserInfoWG';
import ModalDifferentParentOK from '../UserModals/ModalDifferentParentOK';
import ModalSignatureStep1 from '../UserModals/ModalSignatureStep1';
import ModalSignatureStep2 from '../UserModals/ModalSignatureStep2';
import ModalSpillerkontoPayment from '../UserModals/ModalSpillerkontoPayment';
import ModalSignaturePostponed from '../UserModals/ModalSignaturePostponed';
import ModalWaitingForScan from '../UserModals/ModalWaitingForScan';
import ModalPreRegisterForm from '../UserModals/ModalPreRegisterForm';
import ModalBankID from '../UserModals/ModalBankID';
import ModalPEP from '../UserModals/ModalPEP';
import ModalWaitingForCommunication from '../UserModals/ModalWaitingForCommunication';
import ModalWaitingForTerms from '../UserModals/ModalWaitingForTerms';
import ModalWaitingForNewPincode from '../UserModals/ModalWaitingForNewPincode';
import ModalHybridMustBeUser from '../UserModals/ModalHybridMustBeUser';
import ModalWaitingForPEP from '../UserModals/ModalWaitingForPEP';
import ModalUserNeedManualCheck from '../UserModals/ModalUserNeedManualCheck';
import ModalWaitingForScanNfc from '../UserModals/ModalWaitingForScanNfc';
import ModalUserIsBanned from '../UserModals/ModalUserIsBanned';
import ModalBanUser from '../UserModals/ModalBanUser';
import ModalWaitingForRisky from '../UserModals/ModalWaitingForRisky';
import ModalConfirmIdentity from '../UserModals/ModalConfirmIdentity';
import ModalPreRegisterHybridForm from '../UserModals/ModalPreRegisterHybridForm';

function Transactions() {
  const { t } = useTranslation();
  const {
    WGBingoID,
    updateShowModalDifferentParentWG,
    updateCurrentTicketWG,
    updateShowUserInfoWG,
    showUserInfoWG,
    isIdentiyConfirmed,
    updateIsIdentityConfirmed,
    updateIsLoginFromNfc,
    updateIsNeedConfirmation,
    showPreRegisterHybridForm,
    updateShowModalConfirmIdentity,
    showModalConfirmIdentity,
    showModalBanUser,
    updateShowModalUserIsBanned,
    showModalUserIsBanned,
    showWaitingForScanNfc,
    updateShowWaitingForScanNfc,
    updateShowModalWaitingForRisky,
    showModalWaitingForRisky,
    updateShowModalUserNeedManualCheck,
    showModalUserNeedManualCheck,
    showModalWaitingForPEP,
    showModalHybridMustBeUser,
    showModalWaitingForCommunication,
    showModalWaitingForTerms,
    showModalWaitingForNewPincode,
    showModalPEP,
    showBankIDForm,
    showWaitingForScan,
    updateIsPostponedID,
    userParent,
    userChildren,
    userSiblings,
    signatureTemp,
    isLoadingScreen,
    updateIsLoadingScreen,
    showSpillerkontoPayment,
    OKBingoID,
    showModalSignatureStep1,
    showModalSignatureStep2,
    showModalDifferentParentOK,
    updateShowModalDifferentParentOK,
    showUserInfoOK,
    updateShowUserInfoOK,
    updateCurrentTicketOK,
    updateIsHybridUserRegistration,
    updateIsFullUserEditing,
    updateIsFullUserRegistration,
    showModalPictureVerificationPreRegister,
    showModalProfileImage,
    updateUserImageInfo,
    updateScannedTicket,
    updateShowUserInfoSibling,
    showUserInfoSibling,
    updateAdoptedTicket,
    updateShowModalDifferentParent,
    showModalDifferentParent,
    updateIsVerificationNeeded,
    currentUser,
    userInfo,
    updateShowUserInfo,
    isFullUser,
    updateIsCashoutPending,
    showModalUserFound,
    showModalUserNotFound,
    updateRefreshUser,
    session,
    operator,
    hall,
    userLogin,
    updateUserLogin,
    updateCurrentUser,
    updateCurrentUserID,
    updateUserInfo,
    updateIsFullUser,
    updateIsAnonUser,
    updateIsHybridUser,
    updateIsFastUserLogin,
    print,
    updateUserChildren,
    updateUserParent,
    updateParentInfo,
    updateUserSiblings,
    isCashoutPending,
    isAnonUserRegistration,
    isHybridUserEditing,
    updateIsHybridUserEditing,
    isHybridUserRegistration,
    isFullUserRegistration,
    isFullUserEditing,
    showUserInfo,
    showCard,
    showSpillerkonto,
    showKiosk,
    updateShowModalUserNotFound,
    updateShowModalUserFound,
    userToSearch,
    updateOpenSessionTerminal,
    openSessionTerminal,
    showModalEditUserLimits,
    showCardToCash,

    showIsLimitSurpassed,
    parentInfo,
    updateShowIsLimitSurpassed,
    isLimitSurpassed,
    updateIsLimitSurpassed,
    updateIsDailyLimitSurpassed,
    updateIsMonthlyLimitSurpassed,
    updateShowModalIsVerificationNeeded,
    showModalIsVerificationNeeded,
    userLimits,
    updateUserLimits,
    showIsLimitSurpassedTransaction,
    showModalPictureVerification,
    showModalPictureVerificationWarning,

    updateIsPictureVerificationNeeded,
    updateShowModalPictureVerificationWarning,
    isSellerOpen,
    isPaymentOpen,
    showModalSignaturePostponed,
    refreshUser,
    showPreRegisterForm,
    updateShowModalHybridMustBeUser,
  } = useContext(AppContext);

  const { socket } = useContext(SocketContext);
  const socketNFC = useContext(SocketNFCContext);

  const [usersResults, setUsersResults] = useState([]);

  useEffect(() => {
    var data = {
      auth_value: parentInfo ? parentInfo.id : userInfo.id,
      searchByID: true,
      action: 'searchMember',
      session: session,
    };
    socket.emit('searchMember', data);
  }, [refreshUser]);

  const isIdentiyConfirmedRef = useRef(isIdentiyConfirmed);
  useEffect(() => {
    isIdentiyConfirmedRef.current = isIdentiyConfirmed;
  }, [isIdentiyConfirmed]);

  const isFullUserEditingRef = useRef(isFullUserEditing);
  useEffect(() => {
    isFullUserEditingRef.current = isFullUserEditing;
  }, [isFullUserEditing]);
  const isFullUserRegistrationRef = useRef(isFullUserRegistration);
  useEffect(() => {
    isFullUserRegistrationRef.current = isFullUserRegistration;
  }, [isFullUserRegistration]);

  const isSellerOpenRef = useRef(isSellerOpen);
  useEffect(() => {
    isSellerOpenRef.current = isSellerOpen;
  }, [isSellerOpen]);
  const isPaymentOpenRef = useRef(isPaymentOpen);
  useEffect(() => {
    isPaymentOpenRef.current = isPaymentOpen;
  }, [isPaymentOpen]);

  const OKBingoIDRef = useRef(OKBingoID);
  useEffect(() => {
    OKBingoIDRef.current = OKBingoID;
  }, [OKBingoID]);

  const WGBingoIDRef = useRef(WGBingoID);
  useEffect(() => {
    WGBingoIDRef.current = WGBingoID;
  }, [WGBingoID]);

  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  const printRef = useRef(print);
  useEffect(() => {
    printRef.current = print;
  }, [print]);

  const userLoginRef = useRef(userLogin);
  useEffect(() => {
    userLoginRef.current = userLogin;
  }, [userLogin]);

  const userInfoRef = useRef(userInfo);
  useEffect(() => {
    socket.emit('checkIsPostponedID', { userInfo: userInfo });
    userInfoRef.current = userInfo;

    if (!userInfo || Object.keys(userInfo).length === 0) {
      return;
    }

    //FIX DOUBLE SCAN TICKET NOT WORKING
    setTimeout(() => {
      if (
        userInfoRef.current.username.startsWith('anon') &&
        !isSellerOpenRef.current &&
        isIdentiyConfirmedRef.current
      ) {
        updateShowUserInfo(true);
      }
    }, 400);

    console.log('userInfo', userInfo);
  }, [userInfo]);

  const parentInfoRef = useRef(parentInfo);
  useEffect(() => {
    socket.emit('checkIsPostponedID', { userInfo: parentInfo });
    parentInfoRef.current = parentInfo;

    if (!parentInfo || Object.keys(parentInfo).length === 0) {
      return;
    }

    console.log('parentInfo', parentInfo);

    if (
      parentInfo &&
      parentInfo.username &&
      parentInfo.username.startsWith('hybrid') &&
      userInfo.username.startsWith('anon') &&
      !isSellerOpenRef.current
    ) {
      updateShowModalHybridMustBeUser(true);
    }
  }, [parentInfo]);

  useEffect(() => {
    if (!userInfoRef.current.username) {
      return;
    }
    if (currentUser === 'Gjest') {
      return;
    }
    /*
    if (userInfoRef.current.username.startsWith('anon') && !isSellerOpen) {
      updateShowUserInfo(true);
    }
      */

    socket.emit('openSessions', userInfoRef.current.id);

    if (userInfoRef.current.balances.cashout > 0 && isFullUser) {
      updateIsCashoutPending(true);
    }

    updateIsVerificationNeeded(false);
    setTimeout(() => {
      if (userInfoRef.current.username.startsWith('anon') && !isSellerOpen) {
        updateShowUserInfo(true);
      }

      if (parentInfoRef.current) {
        socket.emit('checkLimitStatus', parentInfoRef.current);
      } else {
        socket.emit('checkLimitStatus', userInfoRef.current);
      }

      if (
        userInfoRef.current.username.startsWith('anon') &&
        parentInfoRef.current &&
        parentInfoRef.current.username.startsWith('hybrid') &&
        !isSellerOpenRef.current
      ) {
        updateShowModalHybridMustBeUser(true);
        return;
      }

      if (
        userInfoRef.current.username.startsWith('anon') &&
        parentInfoRef.current &&
        !parentInfoRef.current.username.startsWith('hybrid')
      ) {
        updateShowModalConfirmIdentity(true);
        return;
      }

      if (
        (parentInfoRef.current &&
          parentInfoRef.current['Disabled login reason'] &&
          parentInfoRef.current['Disabled login reason'] !== 'PEP' &&
          parentInfoRef.current['Disabled login reason'] !== 'HRL') ||
        (userInfoRef.current &&
          userInfoRef.current['Disabled login reason'] &&
          userInfoRef.current['Disabled login reason'] !== 'PEP' &&
          userInfoRef.current['Disabled login reason'] !== 'HRL')
      ) {
        updateShowModalUserIsBanned(true);
        return;
      }

      if (
        parentInfoRef.current['Disabled login reason'] === 'PEP' ||
        userInfoRef.current['Disabled login reason'] === 'PEP' ||
        parentInfoRef.current['Disabled login reason'] === 'HRL' ||
        userInfoRef.current['Disabled login reason'] === 'HRL'
      ) {
        updateShowModalUserNeedManualCheck(true);
        return;
      }

      if (
        (parentInfoRef.current &&
          (!parentInfoRef.current['Verification method'] ||
            !parentInfoRef.current['SSN ID'])) ||
        (!parentInfoRef.current &&
          (!userInfoRef.current['Verification method'] ||
            !userInfoRef.current['SSN ID']) &&
          !userInfoRef.current.username.startsWith('anon') &&
          !userInfoRef.current.username.startsWith('hybrid'))
      ) {
        updateIsVerificationNeeded(true);
        updateShowModalIsVerificationNeeded(true);
        return;
      }

      //* IMAGE VERIFICATION
      if (
        (parentInfoRef.current &&
          !parentInfoRef.current['Verification image ID'] &&
          !parentInfoRef.current['Disabled login reason']) ||
        (!parentInfoRef.current &&
          !userInfoRef.current['Verification image ID'] &&
          !userInfoRef.current.username.startsWith('anon') &&
          !userInfoRef.current['Disabled login reason'])
      ) {
        updateIsPictureVerificationNeeded(true);
        if (
          userInfoRef.current &&
          !parentInfoRef.current &&
          userInfoRef.current.username.startsWith('hybrid')
        ) {
          updateShowModalPictureVerificationWarning(false);
          return;
        }
        if (
          parentInfoRef.current &&
          parentInfoRef.current.username.startsWith('hybrid')
        ) {
          updateShowModalPictureVerificationWarning(false);
          return;
        }
        updateShowModalPictureVerificationWarning(true);
        updateIsNeedConfirmation(true);
        return;
      }

      updateShowModalConfirmIdentity(true);
    }, 300);
  }, [userLogin]);

  //* Login task after search, used in several places
  const handleLogin = (data, isOkAdopted, isWgAdopted) => {
    if (isSellerOpenRef.current || isPaymentOpenRef.current) {
      //console.log('Seller or payment is open');
      return;
    }
    //console.log('handleLogin Transactions', data);
    updateShowUserInfoOK(false);
    updateShowUserInfoWG(false);

    if (data.records.length > 1) {
      setUsersResults(data.records);
      updateShowModalUserFound(true);
      updateIsLoadingScreen(false);
      return;
    }
    if (data.records.length === 0) {
      updateIsLoadingScreen(false);
      updateShowModalUserNotFound(true);
      return;
    }

    //Si hay un usuario padre logueado (es padre, pero no hay parentInfo)
    //Si hay un usuario hijo logueado (es hijo, hay por fuerza parentInfo)

    //Scan ticket
    if (
      data.parent &&
      Object.keys(userInfoRef.current).length > 0 &&
      userInfoRef.current &&
      parentInfoRef.current &&
      parseInt(data.parent.parentID) !== parseInt(parentInfoRef.current.id) &&
      parseInt(data.parent.parentID) !== parseInt(userInfoRef.current.id)
    ) {
      var adoptedTicket = {
        parent: data.parent.userInfo,
        ticket: data.records[0],
      };
      updateAdoptedTicket(adoptedTicket);

      setTimeout(() => {
        //updateShowUserInfoOK(true);
        updateShowModalDifferentParent(true);
      }, 500);
      return;
    }

    if (
      data.parent &&
      Object.keys(userInfoRef.current).length > 0 &&
      userInfoRef.current &&
      !parentInfoRef.current &&
      parseInt(data.parent.parentID) !== parseInt(userInfoRef.current.id)
    ) {
      updateParentInfo(userInfoRef.current);

      var adoptedTicket = {
        parent: data.parent.userInfo,
        ticket: data.records[0],
      };
      updateAdoptedTicket(adoptedTicket);

      setTimeout(() => {
        //updateShowUserInfoOK(true);
        updateShowModalDifferentParent(true);
      }, 500);
      return;
    }

    if (
      isOkAdopted &&
      data.records[0] &&
      Object.keys(userInfoRef.current).length > 0 &&
      userInfoRef.current &&
      parentInfoRef.current &&
      parseInt(data.records[0].id) !== parseInt(parentInfoRef.current.id) &&
      parseInt(data.records[0].id) !== parseInt(userInfoRef.current.id)
    ) {
      var adoptedTicket = {
        parent: data.records[0],
        ticket: data.records[0],
      };
      updateAdoptedTicket(adoptedTicket);

      setTimeout(() => {
        //updateShowUserInfoOK(true);
        updateShowModalDifferentParentOK(true);
      }, 500);
      return;
    }

    //Diffent parent, parent logged in
    if (
      isOkAdopted &&
      data.records[0] &&
      Object.keys(userInfoRef.current).length > 0 &&
      userInfoRef.current &&
      !parentInfoRef.current &&
      parseInt(data.records[0].id) !== parseInt(userInfoRef.current.id)
    ) {
      updateParentInfo(userInfoRef.current);

      var adoptedTicket = {
        parent: data.records[0],
        ticket: data.records[0],
      };

      updateAdoptedTicket(adoptedTicket);

      setTimeout(() => {
        //updateShowUserInfoOK(true);
        updateShowModalDifferentParentOK(true);
      }, 500);
      return;
    }

    if (
      isWgAdopted &&
      data.records[0] &&
      Object.keys(userInfoRef.current).length > 0 &&
      userInfoRef.current &&
      parentInfoRef.current &&
      parseInt(data.records[0].id) !== parseInt(parentInfoRef.current.id) &&
      parseInt(data.records[0].id) !== parseInt(userInfoRef.current.id)
    ) {
      var adoptedTicket = {
        parent: data.records[0],
        ticket: data.records[0],
      };
      updateAdoptedTicket(adoptedTicket);

      setTimeout(() => {
        //updateShowUserInfoOK(true);
        updateShowModalDifferentParentWG(true);
      }, 500);
      return;
    }

    //Diffent parent, parent logged in
    if (
      isWgAdopted &&
      data.records[0] &&
      Object.keys(userInfoRef.current).length > 0 &&
      userInfoRef.current &&
      !parentInfoRef.current &&
      parseInt(data.records[0].id) !== parseInt(userInfoRef.current.id)
    ) {
      updateParentInfo(userInfoRef.current);

      var adoptedTicket = {
        parent: data.records[0],
        ticket: data.records[0],
      };

      updateAdoptedTicket(adoptedTicket);

      setTimeout(() => {
        //updateShowUserInfoOK(true);
        updateShowModalDifferentParentWG(true);
      }, 500);
      return;
    }

    /*
    if (
      (data.parent &&
        data.parent.parentID !== userInfoRef.current.id &&
        parentInfoRef.current.id &&
        data.parent.parentID !== parentInfoRef.current.id) ||
      (!parentInfoRef.current.id &&
        data.parent &&
        data.parent.parentID !== userInfoRef.current.id)
    ) {
   
    }
    */

    updateUserLogin(true);
    updateUserInfo(data.records[0]);
    updateCurrentUser(data.records[0]['7 digit PIN']);
    updateCurrentUserID(data.records[0]['id']);

    //updateUserImageInfo(data.userProfileImage);

    //* IMAGE VERIFICATION
    if (data.parent && data.parent.userProfileImage && !parentInfoRef.current) {
      const blob = new Blob([data.parent.userProfileImage.image], {
        type: data.parent.userProfileImage.mimeType,
      });
      const url = URL.createObjectURL(blob);

      // Update state with the image info and URL
      updateUserImageInfo({
        blobName: data.blobName,
        imageUrl: url,
        mimeType: data.mimeType,
      });
    } else if (data.userProfileImage) {
      const blob = new Blob([data.userProfileImage.image], {
        type: data.userProfileImage.mimeType,
      });
      const url = URL.createObjectURL(blob);

      // Update state with the image info and URL
      updateUserImageInfo({
        blobName: data.blobName,
        imageUrl: url,
        mimeType: data.mimeType,
      });
    }

    if (data.siblings) {
      updateUserSiblings(data.siblings);
    }

    if (data.children) {
      updateUserChildren(data.children);
    }

    var dataToRegisterObj = [];
    dataToRegisterObj.push(data.records[0]);
    if (
      data.records[0].username.slice(0, 4) === 'anon' ||
      (data.records[0].firstname === '' && data.records[0].mobile === '')
    ) {
      //ANON
      updateIsFullUser(false);
      updateIsHybridUser(false);
      updateIsAnonUser(true);
      if (data.parent) {
        updateUserParent(data.parent);
        var data = {
          auth_value: data.parent.parentID,
          searchByID: true,
          action: 'searchMemberParent',
          session: session,
        };
        socket.emit('searchMemberParent', data);
      }
      if (data.siblings) {
        updateUserSiblings(data.siblings);
      }
    } else if (data.records[0].username.slice(0, 6) === 'hybrid') {
      //Hybrid, don't do shit
      updateIsFullUser(false);
      updateIsHybridUser(true);
      updateIsAnonUser(false);

      //ANNOUNCEMENT 1st JAN 2025
      updateShowModalHybridMustBeUser(true);
    } else {
      //Parent, save the children if exist
      if (data.children) {
        updateUserChildren(data.children);
      }
      updateIsFullUser(true);
      updateIsHybridUser(false);
      updateIsAnonUser(false);
    }

    updateIsFastUserLogin(false);

    // ANON PRE WORKING
    setTimeout(() => {
      if (
        userInfoRef.current &&
        userInfoRef.current.username &&
        userInfoRef.current.username.startsWith('anon') &&
        !isSellerOpenRef.current
      ) {
        updateShowUserInfo(true);
      }
    }, 500);

    var dataToRegister = {
      action: 'registerLastUsers',
      operator: operator,
      bingoName: hall,
      sessionID: session,
      userInfo: dataToRegisterObj[0],
    };

    registerLastUsers(dataToRegister);
    updateIsLoadingScreen(false);

    if (isHybridUserEditing) {
      updateIsHybridUserEditing(false);
    }
    if (isFullUserEditing) {
      updateIsFullUserEditing(false);
    }
    if (isHybridUserRegistration) {
      updateIsHybridUserRegistration(false);
    }
    if (isFullUserRegistration) {
      updateIsFullUserRegistration(false);
    }
  };

  useEffect(() => {
    const handleNewShoppingCarts = (data) => {
      //console.log("newShoppingCarts", data);
    };

    const handleSearchMember = (data) => {
      console.log('searchMember', data);
      updateRefreshUser(false);
      updateIsLoadingScreen(false);

      if (!data || !data.records) {
        toast.error(t('error.error'));
        return;
      }
      handleLogin(data);
    };

    const handleSearchMemberParent = (data) => {
      updateParentInfo(data.records[0]);
    };

    const handleOpenSessions = (data) => {
      if (data) {
        updateOpenSessionTerminal(data);
      }
    };

    const handleCheckLimitStatus = (data) => {
      updateUserLimits(data);

      if (data.isMonthly) {
        updateIsMonthlyLimitSurpassed(true);
        updateIsLimitSurpassed(true);
        updateShowIsLimitSurpassed(true);
        return;
      }
      if (data.isDaily) {
        updateIsDailyLimitSurpassed(true);
        updateIsLimitSurpassed(true);
        updateShowIsLimitSurpassed(true);
        return;
      }
    };

    const handleSearchMemberHidden = (data) => {
      if (!data || !data.records) {
        toast.error(t('error.error'));
        return;
      }

      updateAdoptedTicket({});
      updateScannedTicket({});
      updateOpenSessionTerminal(false);

      updateShowModalDifferentParentOK(false);
      updateShowModalDifferentParent(false);
      updateShowUserInfoSibling(false);
      updateShowUserInfo(false);
      updateShowUserInfoOK(false);
      updateShowUserInfoWG(false);

      if (
        parseInt(data.parent.parentID) !== userInfoRef.current.id &&
        parseInt(data.parent.parentID) !== parentInfoRef.current.id
      ) {
        var adoptedTicket = {
          parent: data.parent,
          ticket: data.records[0],
        };
        socket.emit('openSessionsAdopted', data.records[0].id);

        updateAdoptedTicket(adoptedTicket);
        updateShowModalDifferentParent(true);

        return;
      }

      if (
        parseInt(data.parent.parentID) === userInfoRef.current.id ||
        parseInt(data.parent.parentID) === parentInfoRef.current.id
      ) {
        //Change this to reload the user like in the list
        //socket.emit("openSessions", data.records[0].id);
        //updateScannedTicket(data.records[0]);
        //updateShowUserInfoSibling(true);

        //This doesn't show the user info modal, need to recheck it
        var data = {
          auth_value: data.records[0]['7 digit PIN'],
          searchByPin: true,
          action: 'searchMember',
          session: session,
        };
        socket.emit('searchMemberMultiScan', data);
      }
    };

    const handleSearchParentOKD = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        return;
      }

      updateAdoptedTicket({});
      updateScannedTicket({});
      updateOpenSessionTerminal(false);

      updateShowModalDifferentParentOK(false);
      updateShowModalDifferentParent(false);
      updateShowUserInfoSibling(false);
      updateShowUserInfo(false);
      updateShowUserInfoOK(false);
      updateShowUserInfoWG(false);

      updateCurrentTicketOK({ okID: data.okID });

      if (
        parseInt(data.parent.id) !== userInfoRef.current.id &&
        parseInt(data.parent.id) !== parentInfoRef.current.id
      ) {
        var adoptedTicket = {
          parent: data.parent,
          ticket: data.okID,
        };
        updateAdoptedTicket(adoptedTicket.parent);
        setTimeout(() => {
          //updateShowUserInfoOK(true);
          updateShowModalDifferentParentOK(true);
        }, 500);
        return;
      } else {
        setTimeout(() => {
          updateShowUserInfoOK(true);
        }, 500);
        return;
      }
    };

    const handleOpenSessionsAdopted = (data) => {
      if (data) {
        updateOpenSessionTerminal(data);
      }
    };

    const handleSearchMemberMultiScan = (data) => {
      if (!data || !data.records) {
        toast.error(t('error.error'));
        return;
      }
      socket.emit('openSessions', data.records[0]['id']);

      handleLogin(data);
    };

    const handleSearchTicketOKD = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        return;
      }

      var isOkAdopted = false;
      //Different parent, children logged in
      if (
        data.records[0] &&
        Object.keys(userInfoRef.current).length > 0 &&
        userInfoRef.current &&
        parentInfoRef.current &&
        parseInt(data.records[0].id) !== parseInt(parentInfoRef.current.id) &&
        parseInt(data.records[0].id) !== parseInt(userInfoRef.current.id)
      ) {
        isOkAdopted = true;
        updateCurrentTicketOK({ okID: data.okID });
        handleLogin(data, isOkAdopted);
        return;
      }

      //Diffent parent, parent logged in
      if (
        data.records[0] &&
        Object.keys(userInfoRef.current).length > 0 &&
        userInfoRef.current &&
        !parentInfoRef.current &&
        parseInt(data.records[0].id) !== parseInt(userInfoRef.current.id)
      ) {
        isOkAdopted = true;
        updateCurrentTicketOK({ okID: data.okID });
        handleLogin(data, isOkAdopted);
        return;
      }

      updateCurrentTicketOK({ okID: data.okID });
      handleLogin(data, isOkAdopted);

      setTimeout(() => {
        updateShowUserInfoOK(true);
      }, 500);
    };

    const handleSearchTicketWG = (data) => {
      console.log('searchTicketWG', data);
      updateIsLoadingScreen(false);
      if (!data) {
        toast.error(t('error.error'));
        return;
      }

      var isWgAdopted = false;
      //Different parent, children logged in
      if (
        data.records[0] &&
        Object.keys(userInfoRef.current).length > 0 &&
        userInfoRef.current &&
        parentInfoRef.current &&
        parseInt(data.records[0].id) !== parseInt(parentInfoRef.current.id) &&
        parseInt(data.records[0].id) !== parseInt(userInfoRef.current.id)
      ) {
        isWgAdopted = true;
        updateCurrentTicketWG(data.ticket);
        handleLogin(data, false, isWgAdopted);
        return;
      }

      //Diffent parent, parent logged in
      if (
        data.records[0] &&
        Object.keys(userInfoRef.current).length > 0 &&
        userInfoRef.current &&
        !parentInfoRef.current &&
        parseInt(data.records[0].id) !== parseInt(userInfoRef.current.id)
      ) {
        isWgAdopted = true;
        updateCurrentTicketWG(data.ticket);
        handleLogin(data, false, isWgAdopted);
        return;
      }

      updateCurrentTicketWG(data.ticket);
      handleLogin(data, false, isWgAdopted);

      setTimeout(() => {
        updateShowUserInfoWG(true);
      }, 500);
    };

    const handleIsPostponedID = (data) => {
      if (data) {
        updateIsPostponedID(true);
      }
    };

    socket.on('checkIsPostponedID', handleIsPostponedID);
    socket.on('checkLimitStatus', handleCheckLimitStatus);
    socket.on('newShoppingCarts', handleNewShoppingCarts);
    socket.on('searchMember', handleSearchMember);
    socket.on('searchMemberMultiScan', handleSearchMemberMultiScan);
    socket.on('searchMemberHidden', handleSearchMemberHidden);
    socket.on('searchParentOKD', handleSearchParentOKD);
    socket.on('searchMemberParent', handleSearchMemberParent);
    socket.on('openSessions', handleOpenSessions);
    socket.on('openSessionsAdopted', handleOpenSessionsAdopted);
    socket.on('searchTicketOKD', handleSearchTicketOKD);
    socket.on('searchTicketWG', handleSearchTicketWG);

    return () => {
      socket.off('checkIsPostponedID', handleIsPostponedID);
      socket.off('checkLimitStatus', handleCheckLimitStatus);
      socket.off('newShoppingCarts', handleNewShoppingCarts);
      socket.off('searchMember', handleSearchMember);
      socket.off('searchMemberHidden', handleSearchMemberHidden);
      socket.off('searchMemberParent', handleSearchMemberParent);
      socket.off('openSessions', handleOpenSessions);
      socket.off('openSessionsAdopted', handleOpenSessionsAdopted);
      socket.off('searchMemberMultiScan', handleSearchMemberMultiScan);
      socket.off('searchTicketOKD', handleSearchTicketOKD);
      socket.off('searchTicketWG', handleSearchTicketWG);
      socket.off('searchParentOKD', handleSearchParentOKD);
    };
  }, [socket]);

  useEffect(() => {
    const handleCardDetected = (data) => {
      if (userLoginRef.current) {
        return;
      }

      if (
        parentInfoRef.current &&
        parentInfoRef.current.username.startsWith('hybrid')
      ) {
        return;
      }

      if (isFullUserRegistrationRef.current || isFullUserEditingRef.current) {
        return;
      }
      if (isSellerOpenRef.current || isPaymentOpenRef.current) {
        toast.error(t('sales.closeSellerOrPayment'));
        updateIsLoadingScreen(false);
        return;
      }

      updateIsLoginFromNfc(true);
      const memberDataToSearch = {
        auth_value: data,
        action: 'searchMember',
        session: session,
        searchByCard: true,
      };
      socket.emit('searchMember', memberDataToSearch);
    };

    const handleScanDetected = async (data) => {
      updateIsLoadingScreen(true);

      if (isFullUserRegistrationRef.current || isFullUserEditingRef.current) {
        return;
      }
      //Block if seller or payment is open
      if (isSellerOpenRef.current || isPaymentOpenRef.current) {
        toast.error(t('sales.closeSellerOrPayment'));
        updateIsLoadingScreen(false);
        return;
      }

      //updateIsLoginFromNfc(false);

      //let scannedData = String.fromCharCode(...data);
      let scannedData = data;

      if (scannedData.includes('http://') || scannedData.includes('https://')) {
        //QR doesn't belong to clubhouse
        if (!scannedData.includes('qr.clubhouse.no')) {
          toast.error(t('sales.invalidTicket'));
          updateIsLoadingScreen(false);
          return;
        }
        const urlMatch = scannedData.match(/https?:\/\/[^\s]+/);
        if (!urlMatch) {
          toast.error(t('sales.invalidTicketUrl'));
          updateIsLoadingScreen(false);
          return;
        }

        scannedData = urlMatch[0];

        // Create a function to extract numeric-only values from a given parameter
        const extractNumericValue = (value) => {
          return value ? value.replace(/\D/g, '') : null; // Remove non-numeric characters
        };

        function cleanString(input) {
          // Use a regular expression to remove ']Q1'
          return input.replace(/s\u0001\u0002\u0003\]Q1/g, '');
        }

        // Extract query parameters using URLSearchParams for better readability
        const url = new URL(scannedData); // Parse the URL
        const params = new URLSearchParams(url.search); // Extract the query string

        // Prepare the data to be sent based on the parameters
        const dataToSearch = {};

        if (params.has('okd')) {
          const okd = cleanString(params.get('okd'));
          if (okd) {
            dataToSearch.okID = okd;
            dataToSearch.action = 'searchTicketOKD';
            dataToSearch.session = session;
            dataToSearch.searchByID = true;
            socket.emit('searchTicketOKD', dataToSearch);
          }
        }

        if (params.has('wg')) {
          const wg = cleanString(params.get('wg'));
          if (wg) {
            dataToSearch.wgID = wg;
            dataToSearch.action = 'searchTicketWG';
            dataToSearch.session = session;
            dataToSearch.searchByID = true;
            dataToSearch.bingoID = WGBingoIDRef.current;
            socket.emit('searchTicketWG', dataToSearch);
          }
        }

        if (params.has('pid')) {
          const pid = params.get('pid');
          // You can handle the PID logic here, similar to OKD
        }

        if (params.has('id')) {
          const memberId = params.get('id');
          if (memberId) {
            const memberDataToSearch = {
              auth_value: memberId,
              action: 'searchMember',
              session: session,
              searchByID: true,
            };
            socket.emit('searchMember', memberDataToSearch);
          }
        }
      } else {
        //Barcode Logic
        /*
        if (userLoginRef.current) {
          updateIsLoadingScreen(false);
          return;
        }
        */

        /*
        if (
          parentInfoRef.current &&
          parentInfoRef.current.username.startsWith('hybrid')
        ) {
          updateIsLoadingScreen(false);
          return;
        }
        */

        scannedData = scannedData.replace(/[^a-zA-Z0-9]/g, '');
        scannedData = await extractSevenDigits(scannedData);
        if (!scannedData || scannedData.length !== 7) {
          //Check if is CH CARD Mxxxxxxxx
          const regexCard = /[Mm]\d{7}/;
          const match = data.match(regexCard);
          if (!match) {
            toast.error(t('error.error'));
            updateIsLoadingScreen(false);
            return;
          }
          var dataToSearch = {
            auth_value: match[0],
            searchByCardCH: true,
            action: 'searchMember',
            session: session,
          };
          socket.emit('searchMember', dataToSearch);
          return;
        }
        var dataToSearch = {
          auth_value: scannedData,
          searchByPin: true,
          action: 'searchMember',
          session: session,
        };
        socket.emit('searchMember', dataToSearch);
      }
    };

    socketNFC.on('scanDetected', handleScanDetected);
    socketNFC.on('cardDetected', handleCardDetected);

    return () => {
      socketNFC.off('scanDetected', handleScanDetected);
      socketNFC.off('cardDetected', handleCardDetected);
    };
  }, [socketNFC]);

  async function extractSevenDigits(inputString) {
    const match = inputString.match(/k1(\d{7})/i);
    if (match) {
      return match[1];
    } else {
      return null;
    }
  }

  const registerLastUsers = (data) => {
    socket.emit('registerLastUsers', data);
  };

  const modalNewCard = (cardID) => {
    confirmAlert({
      title: t('sales.newCardMessage') + ' ID: ' + cardID,
      message: '',
      buttons: [
        {
          label: t('sales.no'),
          onClick: () => {},
          className: 'btn-custom-no',
        },

        {
          label: t('sales.yes'),
          onClick: () => {
            setTimeout(() => {
              updateIsHybridUserEditing(true);
            }, 400);
          },
          className: 'btn-custom-yes',
        },
      ],
    });
  };

  function getIdFromLink(str) {
    if (!str.startsWith('https')) {
      return false;
    }

    try {
      const url = new URL(str);

      const id = url.searchParams.get('id');
      return id;
    } catch (error) {
      return false;
    }
  }

  return (
    <Grid
      container
      sx={{
        height: '100%',
        width: '100%',
        marginTop: 0,
        marginLeft: 0,
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            zIndex: '9999999 !important',
            fontSize: '24px',
          },
        }}
        containerStyle={{
          zIndex: 99999999,
        }}
      />

      {/* MODALS */}
      {showModalUserFound && <ModalUserFound users={usersResults} />}
      {showModalUserNotFound && <ModalUserNotFound />}
      {isFullUserRegistration && <ModalFullUserRegistration />}
      {isFullUserEditing && <ModalFullUserEdit />}
      {isHybridUserRegistration && <ModalHybridUserRegistration />}
      {isHybridUserEditing && <ModalHybridUserEdit />}
      {isAnonUserRegistration && <ModalAnonUserRegistration />}
      {isCashoutPending && <ModalCashoutPending />}
      {showUserInfo && <ModalUserInfo />}
      {showCard && <CardPayment />}
      {showSpillerkonto && <ModalSpillerkonto />}
      {showKiosk && <ModalKiosk />}
      {showModalEditUserLimits && <ModalEditUserLimits />}
      {showCardToCash && <ModalCardToCash />}
      {openSessionTerminal && !showModalDifferentParent && <ModalOpenSession />}
      {showIsLimitSurpassed && <ModalIsLimitSurpassed />}
      {showModalIsVerificationNeeded && <ModalIsVerificationNeeded />}
      {showIsLimitSurpassedTransaction && <ModalIsLimitSurpassedTransaction />}
      {showModalDifferentParent && <ModalDifferentParent />}
      {showModalDifferentParentOK && <ModalDifferentParentOK />}
      {showUserInfoSibling && !openSessionTerminal && <ModalUserInfoSibling />}
      {showModalPictureVerificationWarning && (
        <ModalPictureVerificationWarning />
      )}
      {showModalPictureVerification && <ModalPictureVerification />}
      {showModalPictureVerificationPreRegister && (
        <ModalPictureVerificationPreRegister />
      )}
      {showModalProfileImage && <ModalProfileImage />}
      {showUserInfoOK && <ModalUserInfoOK />}
      {showUserInfoWG && <ModalUserInfoWG />}
      {showModalSignatureStep1 && <ModalSignatureStep1 />}
      {showModalSignatureStep2 && <ModalSignatureStep2 />}
      {showSpillerkontoPayment && <ModalSpillerkontoPayment />}
      {showModalSignaturePostponed && <ModalSignaturePostponed />}
      {showWaitingForScan && <ModalWaitingForScan />}
      {showPreRegisterForm && <ModalPreRegisterForm />}
      {showBankIDForm && <ModalBankID />}
      {showModalPEP && <ModalPEP />}
      {showModalWaitingForCommunication && <ModalWaitingForCommunication />}
      {showModalWaitingForTerms && <ModalWaitingForTerms />}
      {showModalWaitingForNewPincode && <ModalWaitingForNewPincode />}
      {showModalHybridMustBeUser && <ModalHybridMustBeUser />}
      {showModalWaitingForPEP && <ModalWaitingForPEP />}
      {showModalUserNeedManualCheck && <ModalUserNeedManualCheck />}
      {showWaitingForScanNfc && <ModalWaitingForScanNfc />}
      {showModalUserIsBanned && <ModalUserIsBanned />}
      {showModalBanUser && <ModalBanUser />}
      {showModalWaitingForRisky && <ModalWaitingForRisky />}
      {showModalConfirmIdentity && <ModalConfirmIdentity />}
      {showPreRegisterHybridForm && <ModalPreRegisterHybridForm />}

      {/* HEADER CONTAINER */}
      {!userLogin ? <HeaderSearcher /> : <HeaderUserDescription />}

      {userLogin ? (
        <>
          {/* TRANSLIST CONTAINER AND TOTAL BUTTON */}
          <TransactionList />
          <TotalButton />
        </>
      ) : (
        <>{userToSearch.length > 0 ? <LastUsersFiltered /> : <LastUsers />}</>
      )}
    </Grid>
  );
}

export default Transactions;
