import React, { useEffect, useState, useContext, useRef } from 'react';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import toast from 'react-hot-toast';
import MoneyIcon from '@mui/icons-material/Money';
import WGLogo from '../../../assets/img/wingames.png';
import Box from '@mui/material/Box';
import { Skeleton } from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  NextPlan as NextPlanIcon,
} from '@mui/icons-material/';

function ModalUserInfoWG() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState(false);
  const [ticket, setTicket] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [isClosed, setIsClosed] = useState(false);

  const {
    showModalConfirmIdentity,
    WGBingoID,
    updateShowUserInfoWG,
    parentInfo,
    session,
    updateFocusOnMainScreen,
    userInfo,
    operator,
    hall,
    currentTicketWG,
    updateCurrentTicketWG,
    isTerminalBusy,
    updateIsTerminalBusy,
    updateIsLoadingScreen,
    printer,
    updateTransactions,
  } = useContext(AppContext);

  const WGBingoIDRef = useRef(WGBingoID);
  useEffect(() => {
    WGBingoIDRef.current = WGBingoID;
  }, [WGBingoID]);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const loadingRef = useRef(loading);
  useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);

  //* REFS
  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  const operatorRef = useRef(operator);
  useEffect(() => {
    operatorRef.current = operator;
  }, [operator]);

  const hallRef = useRef(hall);
  useEffect(() => {
    hallRef.current = hall;
  }, [hall]);

  const userInfoRef = useRef(userInfo);
  useEffect(() => {
    userInfoRef.current = userInfo;
  }, [userInfo]);

  const parentInfoRef = useRef(parentInfo);
  useEffect(() => {
    parentInfoRef.current = parentInfo;
  }, [parentInfo]);

  const amountRef = useRef(amount);
  useEffect(() => {
    console.log('NEW amount:', amount);
    amountRef.current = amount;
  }, [amount]);

  const printerRef = useRef(printer);
  useEffect(() => {
    printerRef.current = printer;
  }, [printer]);

  const [wgTicketID, setWGTicketID] = useState(false);
  const wgTicketIDRef = useRef(wgTicketID);
  useEffect(() => {
    wgTicketIDRef.current = wgTicketID;
  }, [wgTicketID]);

  const currentTicketWGRef = useRef(currentTicketWG);
  useEffect(() => {
    currentTicketWGRef.current = currentTicketWG;
  }, [currentTicketWG]);

  const ticketRef = useRef(ticket);
  useEffect(() => {
    ticketRef.current = ticket;
  }, [ticket]);

  useEffect(() => {
    console.log('currentTicketWG:', currentTicketWG);
    if (!currentTicketWG) {
      return;
    }

    setAmount(currentTicketWG.currentBalance);
    setIsClosed(currentTicketWG.isCashedOut);
    setLoading(false);
    if (!currentTicketWG.isCashedOut && !currentTicketWG.isInUse) {
      setIsDisabled(false);
    }
  }, [currentTicketWG]);

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const handleCashoutWG = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }
      updateTransactions({
        name: 'Utbetaling' + ' - ' + 'WG',
        //amount: parseInt(amount),
        amount: parseInt(amountRef.current) * -1,
        type: 'transaction',
        ticketData: data,
      });

      updateCurrentTicketWG(false);

      setTimeout(() => {
        updateIsLoadingScreen(false);
        updateShowUserInfoWG(false);
        updateIsTerminalBusy(false);
      }, 300);
    };

    socket.on('cashoutWG', handleCashoutWG);

    return () => {
      socket.off('cashoutWG', handleCashoutWG);
    };
  }, [socket]);

  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const handlePayOut = async () => {
    updateIsTerminalBusy(true);
    updateIsLoadingScreen(true);
    if (isTerminalBusy) {
      return;
    }
    socket.emit('cashoutWG', {
      operator: operator,
      bingoName: hall,
      sessionID: sessionRef.current,
      userInfo: parentInfo ? parentInfo : userInfo,
      amount: parseInt(amountRef.current),
      provider: 'WG',
      description: 'Utbetaling',
      wgID: currentTicketWG.wgID,
      action: 'cashoutWG',
      printer: printer,
      bingoID: WGBingoIDRef.current,
    });
  };

  return (
    <>
      {showModalConfirmIdentity ? (
        <></>
      ) : (
        <div
          style={{
            width: '100%',
            height: '200px',
            position: 'absolute',
            bottom: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <div
            style={{
              width: '800px',
              height: '200px',
              background: 'white',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              padding: '20px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              zIndex: '9999998',
            }}
          >
            {loading ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton
                    style={{
                      width: '175px',
                      height: '250px',
                    }}
                    sx={{ bgcolor: 'grey.500' }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton
                    style={{
                      width: '175px',
                      height: '250px',
                    }}
                    sx={{ bgcolor: 'grey.500' }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton
                    style={{
                      width: '175px',
                      height: '250px',
                    }}
                    sx={{ bgcolor: 'grey.500' }}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton
                    style={{
                      width: '175px',
                      height: '250px',
                    }}
                    sx={{ bgcolor: 'grey.500' }}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 'auto',
                      width: '150px',
                      paddingTop: 0,
                    }}
                    alt=""
                    src={WGLogo}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ color: 'black' }}>{t('sales.ticket7Pincode')}</p>
                  <span
                    style={{
                      fontSize: '22px',
                      color: '#42a5f5',
                      fontWeight: 'bold',
                    }}
                  >
                    {currentTicketWGRef.current &&
                      currentTicketWGRef.current.wgID}
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ color: 'black' }}>{t('sales.currentSaldo')}</p>
                  <span
                    style={{
                      fontSize: '22px',
                      color: '#42a5f5',
                      fontWeight: 'bold',
                    }}
                  >
                    {currentTicketWGRef.current.isCashedOut ? '0' : amount} NOK
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ color: 'black' }}>{t('sales.status')}</p>
                  <span
                    style={{
                      fontSize: '22px',
                      color: '#42a5f5',
                      fontWeight: 'bold',
                    }}
                  >
                    {currentTicketWGRef.current.isInUse ||
                    currentTicketWGRef.current.isInUse
                      ? t('sales.inGame')
                      : currentTicketWGRef.current.isCashedOut ||
                          currentTicketWGRef.current.isCashedOut
                        ? t('sales.closed')
                        : t('sales.open')}
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div className="react-confirm-alert-button-group">
                    <button
                      className="btn-custom-no"
                      onClick={() => {
                        if ((!amount && amount < 1) || isDisabled) {
                          return;
                        }
                        handlePayOut();
                      }}
                      style={{
                        backgroundColor: 'black !important',
                        opacity:
                          (!amount && amount < 1) ||
                          isTerminalBusy ||
                          isDisabled
                            ? '0.3'
                            : 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px', // Space between icon and text
                      }}
                    >
                      <MoneyIcon style={{ fontSize: '1.2em' }} />

                      {t('sales.payout')}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ModalUserInfoWG;
