import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Grid,
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Checkbox,
  FormControlLabel,
  FormControl,
  MenuItem,
} from '@mui/material';
import Select, { components } from 'react-select';

import ClubHouseLogo from '../../assets/img/clubhouse_logo.png';
import { useTranslation } from 'react-i18next';

import { SocketContext } from '../../context/SocketContext';

import toast, { Toaster } from 'react-hot-toast';
import CalculatorItem from './CalculatorItem';
import axios from 'axios';
import {
  CameraAlt as CameraAltIcon,
  RestartAlt as RestartAltIcon,
  SyncAlt as SyncAltIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  QrCodeScanner as ScanIcon,
} from '@mui/icons-material/';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

function UserLogin() {
  const { t } = useTranslation();
  const translations = t('pep', { returnObjects: true });

  const [isStandBy, setIsStandBy] = useState(true);
  const [isPincode, setIsPincode] = useState(false);
  const [isSignature, setIsSignature] = useState(false);
  const [url, setUrl] = useState('');

  const [currentBingoName, setCurrentBingoName] = useState('');
  const [imageInfo, setImageInfo] = useState(false);
  const imageInfoRef = useRef(imageInfo);

  const [showRisky, setShowRisky] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showTermsText, setShowTermsText] = useState(false);
  const [showCommunication, setShowCommunication] = useState(false);
  const [showCreatePincode, setShowCreatePincode] = useState(false);
  const [showPEP, setShowPEP] = useState(false);

  const [communicationOptions, setCommunicationOptions] = useState({
    sms: false,
    email: false,
    offers: false,
    all: true,
  });
  const handleCommunicationChange = (value) => {
    switch (value) {
      case 'sms':
        setCommunicationOptions({
          sms: !communicationOptions.sms,
          email: communicationOptions.email,
          offers: communicationOptions.offers,
          all: false,
        });
        break;
      case 'email':
        setCommunicationOptions({
          sms: communicationOptions.sms,
          email: !communicationOptions.email,
          offers: communicationOptions.offers,
          all: false,
        });
        break;
      case 'offers':
        setCommunicationOptions({
          sms: communicationOptions.sms,
          email: communicationOptions.email,
          offers: !communicationOptions.offers,
          all: false,
        });
        break;
      case 'all':
        setCommunicationOptions({
          sms: false,
          email: false,
          offers: false,
          all: true,
        });
        break;
      default:
        break;
    }
  };

  const [termsOptions, setTermsOptions] = useState({
    option1: false,
    option2: false,
  });
  const handleTermsChange = (value) => {
    switch (value) {
      case 'option1':
        setTermsOptions({
          option1: !termsOptions.option1,
          option2: termsOptions.option2,
        });
        break;
      case 'option2':
        setTermsOptions({
          option1: termsOptions.option1,
          option2: !termsOptions.option2,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    imageInfoRef.current = imageInfo;
  }, [imageInfo]);

  const currentBingoNameRef = useRef(currentBingoName);
  useEffect(() => {
    currentBingoNameRef.current = currentBingoName;
  }, [currentBingoName]);

  const canvasRef = useRef(null);
  const [drawing, setDrawing] = useState(false);

  const getUrlParameter = (name) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  };

  useEffect(() => {
    const hallParam = getUrlParameter('hall');
    if (hallParam) {
      setCurrentBingoName(hallParam);
    }
    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
      setUrl('https://stage01-operator.vpfscandinavia.com/api/new-signature');
    } else if (hostname.includes('stage01')) {
      setUrl('https://stage01-operator.vpfscandinavia.com/api/new-signature');
    } else if (hostname.includes('stage02')) {
      setUrl('https://stage02-operator.vpfscandinavia.com/api/new-signature');
    } else {
      setUrl('https://operator.vpfscandinavia.com/api/new-signature');
    }
  }, []);

  const getCoordinates = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    if (event.touches) {
      // Touch event
      const touch = event.touches[0]; // Get the first touch
      return {
        x: touch.clientX - rect.left,
        y: touch.clientY - rect.top,
      };
    } else {
      // Mouse event
      return {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };
    }
  };

  const startDrawing = (event) => {
    const coordinates = getCoordinates(event);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.moveTo(coordinates.x, coordinates.y);
    setDrawing(true);
  };

  const draw = (event) => {
    if (!drawing) return;
    event.preventDefault(); // Prevent scrolling when touching
    const coordinates = getCoordinates(event);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.lineTo(coordinates.x, coordinates.y);
    ctx.stroke();
  };

  const stopDrawing = () => {
    setDrawing(false);
  };

  const reset = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clears the entire canvas
  };

  const { socket } = useContext(SocketContext);

  const [scrapedData, setScrapedData] = useState(null);
  //Get Terms
  useEffect(() => {
    socket.emit('scrapTerms', '');

    return () => {
      socket.disconnect();
    };
  }, []);

  var send = false;
  useEffect(() => {
    const handleSendPincode = (data) => {
      send = false;
      if (!data) {
        toast.error('Feil Kode');
      }
    };

    const handleActivateSignature = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        setIsPincode(false);
        setIsStandBy(false);
        setIsSignature(true);
      }
    };

    const handleActivateSMS = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        setIsPincode(true);
        setIsStandBy(false);
        setIsSignature(false);
        setAmount('');
      }
    };

    const handleDeactivateSignature = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        reset();
        setIsPincode(false);
        setIsStandBy(true);
        setIsSignature(false);
      }
    };

    const handleDeactivateSMS = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        setIsPincode(false);
        setIsStandBy(true);
        setIsSignature(false);
      }
    };

    const handleUploadImageSignature = (data) => {
      reset();
      backToStandBy();
      socket.emit('newSignature', {
        bingoName: currentBingoName,
        uploadID: data.uploadID,
        url: data.url,
        imageInfo: imageInfoRef.current,
      });

      setImageInfo(false);
    };

    const handleActivateTerms = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        setIsPincode(false);
        setIsStandBy(false);
        setIsSignature(false);
        setAmount('');

        setShowTerms(true);
        setShowCommunication(false);
        setShowCreatePincode(false);
        setShowPEP(false);
      }
    };

    const handleActivateCommunication = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        setIsPincode(false);
        setIsStandBy(false);
        setIsSignature(false);
        setAmount('');

        setShowTerms(false);
        setShowCommunication(true);
        setShowCreatePincode(false);
        setShowPEP(false);
      }
    };

    const handleActivatePEP = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        setIsPincode(false);
        setIsStandBy(false);
        setIsSignature(false);
        setAmount('');

        setShowTerms(false);
        setShowCommunication(false);
        setShowCreatePincode(false);
        setShowPEP(true);
      }
    };

    const handleActivateNewPincode = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        setIsPincode(false);
        setIsStandBy(false);
        setIsSignature(false);
        setAmount('');

        setShowTerms(false);
        setShowCommunication(false);
        setShowCreatePincode(true);
        setShowPEP(false);
      }
    };

    const handleDeactivateTerms = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        setIsPincode(false);
        setIsStandBy(true);
        setIsSignature(false);
        setAmount('');

        setShowTerms(false);
        setShowCommunication(false);
        setShowCreatePincode(false);
        setShowPEP(false);
        backToStandBy();
      }
    };

    const handleDeactivateCommunication = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        setIsPincode(false);
        setIsStandBy(true);
        setIsSignature(false);
        setAmount('');

        setShowTerms(false);
        setShowCommunication(false);
        setShowCreatePincode(false);
        setShowPEP(false);
        backToStandBy();
      }
    };

    const handleDeactivatePEP = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        setIsPincode(false);
        setIsStandBy(true);
        setIsSignature(false);
        setAmount('');

        setShowTerms(false);
        setShowCommunication(false);
        setShowCreatePincode(false);
        setShowPEP(false);
        backToStandBy();
      }
    };

    const handleDeactivateNewPincode = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        setIsPincode(false);
        setIsStandBy(true);
        setIsSignature(false);
        setAmount('');

        setShowTerms(false);
        setShowCommunication(false);
        setShowCreatePincode(false);
        setShowPEP(false);
      }
    };

    const handleActivateRisky = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        setIsPincode(false);
        setIsStandBy(false);
        setIsSignature(false);
        setAmount('');

        setShowTerms(false);
        setShowCommunication(false);
        setShowCreatePincode(false);
        setShowPEP(false);
        setShowRisky(true);
      }
    };
    const handleDeactivateRisky = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        setIsPincode(false);
        setIsStandBy(true);
        setIsSignature(false);
        setAmount('');

        setShowTerms(false);
        setShowCommunication(false);
        setShowCreatePincode(false);
        setShowPEP(false);
        setShowRisky(false);
      }
    };

    const handleScrapTerms = (data) => {
      setScrapedData(data);
    };

    const handleTerminalToStandBy = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        setIsPincode(false);
        setIsStandBy(true);
        setIsSignature(false);
        setAmount('');
        backToStandBy();
      }
    };

    // Attach event listeners
    socket.on('terminalToStandBy', handleTerminalToStandBy);
    socket.on('sendPincode', handleSendPincode);
    socket.on('activateSMS', handleActivateSMS);
    socket.on('activateSignature', handleActivateSignature);
    socket.on('deactivateSMS', handleDeactivateSMS);
    socket.on('deactivateSignature', handleDeactivateSignature);
    socket.on('uploadImageSignature', handleUploadImageSignature);

    socket.on('activateTerms', handleActivateTerms);
    socket.on('activateCommunication', handleActivateCommunication);
    socket.on('activatePEP', handleActivatePEP);
    socket.on('activateNewPincode', handleActivateNewPincode);

    socket.on('deactivateTerms', handleDeactivateTerms);
    socket.on('deactivateCommunication', handleDeactivateCommunication);
    socket.on('deactivatePEP', handleDeactivatePEP);
    socket.on('deactivateNewPincode', handleDeactivateNewPincode);

    socket.on('activateRisky', handleActivateRisky);
    socket.on('deactivateRisky', handleDeactivateRisky);

    socket.on('scrapTerms', handleScrapTerms);

    return () => {
      socket.off('terminalToStandBy', handleTerminalToStandBy);
      socket.off('sendPincode', handleSendPincode);
      socket.off('activateSMS', handleActivateSMS);
      socket.off('activateSignature', handleActivateSignature);
      socket.off('deactivateSMS', handleDeactivateSMS);
      socket.off('deactivateSignature', handleDeactivateSignature);
      socket.off('uploadImageSignature', handleUploadImageSignature);

      socket.off('activateTerms', handleActivateTerms);
      socket.off('activateCommunication', handleActivateCommunication);
      socket.off('activatePEP', handleActivatePEP);
      socket.off('activateNewPincode', handleActivateNewPincode);

      socket.off('deactivateTerms', handleDeactivateTerms);
      socket.off('deactivateCommunication', handleDeactivateCommunication);
      socket.off('deactivatePEP', handleDeactivatePEP);
      socket.off('deactivateNewPincode', handleDeactivateNewPincode);

      socket.off('activateRisky', handleActivateRisky);
      socket.off('deactivateRisky', handleDeactivateRisky);

      socket.off('scrapTerms', handleScrapTerms);
    };
  }, [socket]);

  const [amount, setAmount] = useState('');

  useEffect(() => {
    /*
    if (send) {
      return;
    }
    if (amount.length === 4) {
      send = true;
      socket.emit("sendPincode", {
        bingoName: currentBingoName,
        pincode: amount,
      });

      setAmount("");
      backToStandBy();
    }
    */
  }, [amount]);

  const updateAmount = (value) => {
    if (value === 'confirm') {
      if (send) {
        return;
      }
      if (amount.length === 4) {
        send = true;
        socket.emit('sendPincode', {
          bingoName: currentBingoName,
          pincode: amount,
        });

        setAmount('');
        backToStandBy();
      }
      return;
    }
    if (value === 'confirmNewPincode') {
      if (send) {
        return;
      }
      if (amount.length === 4) {
        send = true;
        socket.emit('createNewPincode', {
          bingoName: currentBingoName,
          pincode: amount,
        });

        setAmount('');
        backToStandBy();
      }
      return;
    }
    if (amount.length >= 4 && value !== 'backspace') {
      return;
    }

    if (amount === '' && value === 'backspace') {
      return;
    }
    /*
    if (amount === "0" && value !== "backspace") {
      setAmount(value);
      return;
    }
    if (amount === "0" && value === "backspace") {
      return;
    }
    */
    if (value === 'backspace' && amount.length === 1 && amount !== 0) {
      setAmount('');
      return;
    }
    if (value === 'backspace' && amount.length > 1 && amount !== 0) {
      setAmount(amount.substring(0, amount.length - 1));
      return;
    }

    setAmount(amount + value);
  };

  const backToStandBy = () => {
    setIsPincode(false);
    setIsStandBy(true);
    setIsSignature(false);

    setShowTerms(false);
    setShowCommunication(false);
    setShowCreatePincode(false);
    setShowPEP(false);
    setShowRisky(false);
    setCommunicationOptions({
      sms: false,
      email: false,
      offers: false,
      all: true,
    });

    setTermsOptions({
      option1: false,
      option2: false,
    });

    //PEP
    setSelectedFunds(false);
    setSelectedPosition(false);
    setFundsPEP(false);
    setFundsOption1(false);
    setFundsOption2(false);
    setFundsOption3(false);
    setFundsOption4(false);
    setFundsOption5(false);
    setPositionPEP(false);
    setConsent1(false);
    setConsent2(false);
    setConsent3(false);
  };

  const sendCanvasAsBlob = () => {
    const canvas = canvasRef.current;
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    const milliseconds = now.getMilliseconds().toString().padStart(3, '0');

    var filename = `signature_${year}${month}${day}${hours}${minutes}${seconds}.png`;

    // Convert canvas to data URL
    const imageUrl = canvas.toDataURL('image/png');

    // Convert the data URL to a buffer
    const byteString = atob(imageUrl.split(',')[1]);
    const mimeType = imageUrl.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);

    for (let j = 0; j < byteString.length; j++) {
      intArray[j] = byteString.charCodeAt(j);
    }

    setImageInfo({
      filename: filename,
      fileBuffer: intArray,
    });

    // Emit the image data to the server
    socket.emit('uploadImageSignature', {
      fileBuffer: intArray,
      fileName: filename,
    });
    /*
    canvas.toBlob((blob) => {
      const formData = new FormData();
      formData.append("file", blob, filename);

      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          reset();
          backToStandBy();
          socket.emit("newSignature", { bingoName: currentBingoName });
        })
        .catch((error) => {
          toast(t("error.error"));
        });
    }, "image/png");
    */
  };

  const getRandomPosition = (max) => Math.floor(Math.random() * max);

  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const updatePosition = () => {
      const newTop = getRandomPosition(window.innerHeight - 300); // Adjust based on logo height
      const newLeft = getRandomPosition(window.innerWidth - 300); // Adjust based on logo width
      setPosition({ top: newTop, left: newLeft });
    };

    const intervalId = setInterval(updatePosition, 3000); // Update position every 3 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  //*HANDLES REGISTER
  const handleConfirmCommunication = () => {
    socket.emit('createNewCommunication', {
      bingoName: currentBingoName,
      communicationOptions: communicationOptions,
    });
    backToStandBy();
  };

  const handleConfirmTerms = () => {
    if (!termsOptions.option1 || !termsOptions.option2) {
      toast.error(t('terms.required'));
      return;
    }
    socket.emit('createNewTerms', {
      bingoName: currentBingoName,
      terms: true,
    });
    backToStandBy();
  };

  const handleConfirmPep = () => {
    if (!consent1 || !consent2 || !consent3 || !positionPEP) {
      toast.error(t('pep.required'));
      return;
    }

    if (
      !fundsOption1 &&
      !fundsOption2 &&
      !fundsOption3 &&
      !fundsOption4 &&
      !fundsOption5
    ) {
      toast.error(t('pep.required'));
      return;
    }

    var stringFundsOption1 = fundsOption1 ? '1' : '0';
    var stringFundsOption2 = fundsOption2 ? '1' : '0';
    var stringFundsOption3 = fundsOption3 ? '1' : '0';
    var stringFundsOption4 = fundsOption4 ? '1' : '0';
    var stringFundsOption5 = fundsOption5 ? '1' : '0';

    var fundsString = `${stringFundsOption1},${stringFundsOption2},${stringFundsOption3},${stringFundsOption4},${stringFundsOption5}`;

    socket.emit('createNewPep', {
      bingoName: currentBingoName,
      position: positionPEPRef.current,
      funds: fundsString,
      consent: '1,1,1',
    });
    backToStandBy();
  };

  const handleConfirmRisky = () => {
    if (!consent1 || !consent2 || !consent3) {
      toast.error(t('pep.riskyRequired'));
      return;
    }

    if (
      !fundsOption1 &&
      !fundsOption2 &&
      !fundsOption3 &&
      !fundsOption4 &&
      !fundsOption5
    ) {
      toast.error(t('pep.riskyRequired'));
      return;
    }

    var stringFundsOption1 = fundsOption1 ? '1' : '0';
    var stringFundsOption2 = fundsOption2 ? '1' : '0';
    var stringFundsOption3 = fundsOption3 ? '1' : '0';
    var stringFundsOption4 = fundsOption4 ? '1' : '0';
    var stringFundsOption5 = fundsOption5 ? '1' : '0';

    var fundsString = `${stringFundsOption1},${stringFundsOption2},${stringFundsOption3},${stringFundsOption4},${stringFundsOption5}`;

    socket.emit('createNewRisky', {
      bingoName: currentBingoName,
      isRiskyString: fundsString + ',1,1,1',
    });
    backToStandBy();
  };

  //*PEP
  const [selectedFunds, setSelectedFunds] = useState(false);
  const selectedFundsRef = useRef(selectedFunds);
  useEffect(() => {
    selectedFundsRef.current = selectedFunds;
  }, [selectedFunds]);
  const [fundsPEP, setFundsPEP] = useState(false);
  const fundsPEPRef = useRef(fundsPEP);
  useEffect(() => {
    fundsPEPRef.current = fundsPEP;
  }, [fundsPEP]);
  const handleFundsChange = (selectedOption) => {
    setFundsPEP(selectedOption.value);
    setSelectedFunds(selectedOption);
  };
  const [fundsOption1, setFundsOption1] = useState(false);
  const [fundsOption2, setFundsOption2] = useState(false);
  const [fundsOption3, setFundsOption3] = useState(false);
  const [fundsOption4, setFundsOption4] = useState(false);
  const [fundsOption5, setFundsOption5] = useState(false);

  const handleFundsOption1 = (event) => {
    setFundsOption1(event.target.checked);
  };
  const fundsOption1Ref = useRef(fundsOption1);
  useEffect(() => {
    fundsOption1Ref.current = fundsOption1;
  }, [fundsOption1]);

  const handleFundsOption2 = (event) => {
    setFundsOption2(event.target.checked);
  };
  const fundsOption2Ref = useRef(fundsOption2);
  useEffect(() => {
    fundsOption2Ref.current = fundsOption2;
  }, [fundsOption2]);

  const handleFundsOption3 = (event) => {
    setFundsOption3(event.target.checked);
  };
  const fundsOption3Ref = useRef(fundsOption3);
  useEffect(() => {
    fundsOption3Ref.current = fundsOption3;
  }, [fundsOption3]);

  const handleFundsOption4 = (event) => {
    setFundsOption4(event.target.checked);
  };
  const fundsOption4Ref = useRef(fundsOption4);
  useEffect(() => {
    fundsOption4Ref.current = fundsOption4;
  }, [fundsOption4]);

  const handleFundsOption5 = (event) => {
    setFundsOption5(event.target.checked);
  };
  const fundsOption5Ref = useRef(fundsOption5);
  useEffect(() => {
    fundsOption5Ref.current = fundsOption5;
  }, [fundsOption5]);

  const optionsFunds = [
    { value: 'Lønn', label: t('pep.funds.0') },
    { value: 'Salg/utleie av eiendom', label: t('pep.funds.1') },
    { value: 'Salg av aksjer eller fond', label: t('pep.funds.2') },
    {
      value: 'Trygd, pensjon, studielån eller andre støtteordninger',
      label: t('pep.funds.3'),
    },
    { value: 'Gaver, arv eller lignende', label: t('pep.funds.4') },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '277.74px',
      height: '56px',
      minHeight: '58px',
      border: state.isFocused ? 'noone' : '1px solid #464646',
      boxShadow: 'none',
      '&:hover': { borderColor: '#ced4da' },
      borderColor: state.isFocused ? '#90caf9' : provided.borderColor,
      outline: state.isFocused ? '1px solid #90caf9' : 'none',
      outlineOffset: '0px',
    }),
    option: (provided, { data }) => ({
      ...provided,
      borderBottom: data.isDivider ? '1px solid #ccc' : 'none',
      padding: data.isDivider ? '0' : provided.padding,
      backgroundColor: data.isDivider
        ? 'transparent'
        : provided.backgroundColor,
      cursor: data.isDivider ? 'default' : 'pointer',
      fontSize: '21px',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure the dropdown layers above other content
    }),
  };

  const optionsPosition = [
    {
      value: 'government',
      label: t('pep.positions.0'),
    },
    {
      value: 'assembly',
      label: t('pep.positions.1'),
    },
    {
      value: 'political_party',
      label: t('pep.positions.2'),
    },
    {
      value: 'supreme_court',
      label: t('pep.positions.3'),
    },
    {
      value: 'audit',
      label: t('pep.positions.4'),
    },
    {
      value: 'embassy',
      label: t('pep.positions.5'),
    },
    {
      value: 'supervisor',
      label: t('pep.positions.6'),
    },
    {
      value: 'top_management',
      label: t('pep.positions.7'),
    },
  ];
  const [positionPEP, setPositionPEP] = useState(false);
  const positionPEPRef = useRef(positionPEP);
  useEffect(() => {
    positionPEPRef.current = positionPEP;
  }, [positionPEP]);
  const [selectedPosition, setSelectedPosition] = useState(false);

  const handlePositionChange = (selectedOption) => {
    setPositionPEP(selectedOption.value);
    setSelectedPosition(selectedOption);
  };

  const [consent1, setConsent1] = useState(false);
  const [consent2, setConsent2] = useState(false);
  const [consent3, setConsent3] = useState(false);

  const handleConsent1 = (event) => {
    setConsent1(event.target.checked);
  };
  const consent1Ref = useRef(consent1);
  useEffect(() => {
    consent1Ref.current = consent1;
  }, [consent1]);

  const handleConsent2 = (event) => {
    setConsent2(event.target.checked);
  };
  const consent2Ref = useRef(consent2);
  useEffect(() => {
    consent2Ref.current = consent2;
  }, [consent2]);

  const handleConsent3 = (event) => {
    setConsent3(event.target.checked);
  };
  const consent3Ref = useRef(consent3);
  useEffect(() => {
    consent3Ref.current = consent3;
  }, [consent3]);

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        marginTop: 0,
        backgroundColor: '#000', // Black background
      }}
    >
      <Toaster position="top-center" reverseOrder={false} />

      {showTermsText && scrapedData && (
        <div
          style={{
            height: '100%',
            justifyContent: 'center',
            alignContent: 'center',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '40px',
          }}
        >
          <div
            style={{
              color: 'black',
              background: 'white',
              padding: '50px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              overflow: 'auto',
            }}
          >
            {/* Close Icon */}
            <IconButton
              onClick={() => {
                setShowTerms(true);
                setShowTermsText(false);
              }}
              style={{
                position: 'absolute',
                top: '15px',
                right: '15px',
                background: 'white',
                color: 'black',

                border: '1px solid black',
                boxShadow: '0px 0px 10px 0px black',
                height: '75px',
                width: '75px',
              }}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
            <div
              style={{
                color: 'black',
                background: 'white',
              }}
            >
              {scrapedData.map((item, index) => {
                // Dynamically render the appropriate HTML tag
                const Tag = item.tag; // Use the tag dynamically, e.g., 'h6', 'p'
                return (
                  <Tag
                    key={index}
                    style={{
                      fontSize: item.fontSize, // Apply font size dynamically
                      margin: Tag === 'h6' ? '0px 0px' : 'auto',
                    }}
                  >
                    {item.text}
                  </Tag>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {showTerms && (
        <Grid
          item
          xs={12}
          sx={{
            padding: '5px',
            height: 'calc(100% - 25px)',
            width: '100%',
            marginTop: 0,
            marginLeft: 0,
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            color: 'black', // White text color
          }}
        >
          <div
            style={{
              height: '100%',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <div
              style={{
                color: 'black',
                background: 'white',
                padding: '50px',
                borderRadius: '10px',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <h2 style={{ fontSize: '28px' }}>{t('terms.title')}</h2>

              <div style={{ marginBottom: '35px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={termsOptions.option1}
                      onChange={() => {
                        handleTermsChange('option1');
                      }}
                      name="checked"
                      color="primary"
                      sx={{
                        transform: 'scale(2)', // Increases the size of the checkbox
                        color: 'black',
                        '&.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      style={{
                        color: 'black',
                        marginLeft: '10px',
                        fontSize: '1.4rem',
                      }}
                    >
                      {t('terms.termsText1')}
                      <span
                        style={{
                          color: 'blue',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent the click from propagating to the checkbox
                          setShowTerms(false);
                          setShowTermsText(true);
                        }}
                      >
                        {t('terms.termsText2')}
                      </span>{' '}
                      {' *'}
                    </Typography>
                  }
                  sx={{
                    '.MuiTypography-root': {
                      fontSize: '1.4rem', // Increases the label font size
                      lineHeight: '1.5', // Ensures proper line spacing
                      marginLeft: '10px',
                    },
                    alignItems: 'center', // Vertically aligns the label
                    marginTop: '10px',
                    marginLeft: '10px',
                  }}
                />
              </div>

              <div style={{ marginBottom: '35px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={termsOptions.option2}
                      onChange={() => {
                        handleTermsChange('option2');
                      }}
                      name="checked"
                      color="primary"
                      sx={{
                        transform: 'scale(2)', // Increases the size of the checkbox
                        color: 'black',
                        '&.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                    />
                  }
                  label={t('terms.accountText')}
                  sx={{
                    '.MuiTypography-root': {
                      fontSize: '1.4rem', // Increases the label font size
                      lineHeight: '1.5', // Ensures proper line spacing
                      marginLeft: '10px',
                    },
                    alignItems: 'center', // Vertically aligns the label with the 3
                    marginTop: '10px',
                    marginLeft: '10px',
                  }}
                />
              </div>

              <div
                className="react-confirm-alert-button-group"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  width: '100%',
                }}
              >
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    handleConfirmTerms();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CheckCircleIcon style={{ fontSize: '1.2em' }} />
                  {t('sales.confirm')}
                </button>
              </div>
            </div>
          </div>
        </Grid>
      )}

      {showCommunication && (
        <Grid
          item
          xs={12}
          sx={{
            padding: '5px',
            height: 'calc(100% - 25px)',
            width: '100%',
            marginTop: 0,
            marginLeft: 0,
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            color: 'black', // White text color
          }}
        >
          <div
            style={{
              height: '100%',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <div
              style={{
                color: 'black',
                background: 'white',
                padding: '50px',
                borderRadius: '10px',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <h2 style={{ fontSize: '28px' }}>{t('communication.title')}</h2>
              <p style={{ fontSize: '22px' }}>{t('communication.subtitle')}</p>

              <div
                style={{
                  marginTop: '25px',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={communicationOptions.sms}
                      onChange={() => {
                        handleCommunicationChange('sms');
                      }}
                      name="checked"
                      color="primary"
                      sx={{
                        transform: 'scale(2)', // Increases the size of the checkbox
                        color: 'black',
                        '&.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                    />
                  }
                  label={t('communication.option1')}
                  sx={{
                    '.MuiTypography-root': {
                      fontSize: '1.4rem', // Increases the label font size
                      lineHeight: '1.5', // Ensures proper line spacing
                      marginLeft: '10px',
                    },
                    alignItems: 'center', // Vertically aligns the label with the 3
                    marginTop: '10px',
                    marginLeft: '10px',
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: '25px',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={communicationOptions.email}
                      onChange={() => {
                        handleCommunicationChange('email');
                      }}
                      name="checked"
                      color="primary"
                      sx={{
                        transform: 'scale(2)', // Increases the size of the checkbox
                        color: 'black',
                        '&.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                    />
                  }
                  label={t('communication.option2')}
                  sx={{
                    '.MuiTypography-root': {
                      fontSize: '1.4rem', // Increases the label font size
                      lineHeight: '1.5', // Ensures proper line spacing
                      marginLeft: '10px',
                    },
                    alignItems: 'center', // Vertically aligns the label with the 3
                    marginTop: '10px',
                    marginLeft: '10px',
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: '25px',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={communicationOptions.offers}
                      onChange={() => {
                        handleCommunicationChange('offers');
                      }}
                      name="checked"
                      color="primary"
                      sx={{
                        transform: 'scale(2)', // Increases the size of the checkbox
                        color: 'black',
                        '&.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                    />
                  }
                  label={t('communication.option3')}
                  sx={{
                    '.MuiTypography-root': {
                      fontSize: '1.4rem', // Increases the label font size
                      lineHeight: '1.5', // Ensures proper line spacing
                      marginLeft: '10px',
                    },
                    alignItems: 'center', // Vertically aligns the label with the 3
                    marginTop: '10px',
                    marginLeft: '10px',
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: '25px',
                  marginBottom: '45px',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={communicationOptions.all}
                      onChange={() => {
                        handleCommunicationChange('all');
                      }}
                      name="checked"
                      color="primary"
                      sx={{
                        transform: 'scale(2)', // Increases the size of the checkbox
                        color: 'black',
                        '&.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                    />
                  }
                  label={t('communication.option4')}
                  sx={{
                    '.MuiTypography-root': {
                      fontSize: '1.4rem', // Increases the label font size
                      lineHeight: '1.5', // Ensures proper line spacing
                      marginLeft: '10px',
                    },
                    alignItems: 'center', // Vertically aligns the label with the 3
                    marginTop: '10px',
                    marginLeft: '10px',
                  }}
                />
              </div>

              <div
                className="react-confirm-alert-button-group"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  width: '100%',
                }}
              >
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    handleConfirmCommunication();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CheckCircleIcon style={{ fontSize: '1.2em' }} />
                  {t('sales.confirm')}
                </button>
              </div>
            </div>
          </div>
        </Grid>
      )}

      {showPEP && (
        <Grid
          item
          xs={12}
          sx={{
            padding: '5px',
            height: 'calc(100% - 25px)',
            width: '100%',
            marginTop: 0,
            marginLeft: 0,
            justifyContent: 'center',
            alignContent: 'center',
            color: 'white', // White text color
            overflow: 'auto',
          }}
        >
          <div
            style={{
              height: '100%',
              overflow: 'auto',
            }}
          >
            <div
              style={{
                color: 'black',
                background: 'white',
                padding: '20px',
                borderRadius: '10px',
              }}
            >
              <h2 style={{ fontSize: '28px' }}>{translations.pepQuestion}</h2>
              <p style={{ fontSize: '22px' }}>{translations.pepDescription}</p>

              <div
                style={{
                  marginTop: '25px',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ marginBottom: '10px', fontSize: '22px' }}
                >
                  {translations.pepUncertain}
                </Typography>
                <Select
                  className="modal-textfield-select"
                  styles={customStyles}
                  value={selectedPosition}
                  onChange={handlePositionChange}
                  options={optionsPosition}
                  isClearable={false}
                  placeholder={t('pep.checkList') + '*'}
                />
              </div>

              {/* Funds Section */}
              <div
                style={{
                  marginTop: '25px',
                }}
              >
                <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                  {translations.fundsQuestion}
                </Typography>
                <div style={{ marginBottom: '35px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fundsOption1}
                        onChange={handleFundsOption1}
                        color="primary"
                        sx={{
                          transform: 'scale(2)', // Increases the size of the checkbox
                          color: 'black',
                          '&.Mui-checked': {
                            color: 'primary.main',
                          },
                        }}
                      />
                    }
                    label={t('pep.funds.0')}
                    sx={{
                      '.MuiTypography-root': {
                        fontSize: '1.4rem', // Increases the label font size
                        lineHeight: '1.5', // Ensures proper line spacing
                        marginLeft: '10px',
                      },
                      alignItems: 'center', // Vertically aligns the label with the 3
                      marginTop: '10px',
                      marginLeft: '10px',
                    }}
                  />
                </div>
                <div style={{ marginBottom: '35px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fundsOption2}
                        onChange={handleFundsOption2}
                        color="primary"
                        sx={{
                          transform: 'scale(2)', // Increases the size of the checkbox
                          color: 'black',
                          '&.Mui-checked': {
                            color: 'primary.main',
                          },
                        }}
                      />
                    }
                    label={t('pep.funds.1')}
                    sx={{
                      '.MuiTypography-root': {
                        fontSize: '1.4rem', // Increases the label font size
                        lineHeight: '1.5', // Ensures proper line spacing
                        marginLeft: '10px',
                      },
                      alignItems: 'center', // Vertically aligns the label with the 3
                      marginTop: '10px',
                      marginLeft: '10px',
                    }}
                  />
                </div>
                <div style={{ marginBottom: '35px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fundsOption3}
                        onChange={handleFundsOption3}
                        color="primary"
                        sx={{
                          transform: 'scale(2)', // Increases the size of the checkbox
                          color: 'black',
                          '&.Mui-checked': {
                            color: 'primary.main',
                          },
                        }}
                      />
                    }
                    label={t('pep.funds.2')}
                    sx={{
                      '.MuiTypography-root': {
                        fontSize: '1.4rem', // Increases the label font size
                        lineHeight: '1.5', // Ensures proper line spacing
                        marginLeft: '10px',
                      },
                      alignItems: 'center', // Vertically aligns the label with the 3
                      marginTop: '10px',
                      marginLeft: '10px',
                    }}
                  />
                </div>
                <div style={{ marginBottom: '35px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fundsOption4}
                        onChange={handleFundsOption4}
                        color="primary"
                        sx={{
                          transform: 'scale(2)', // Increases the size of the checkbox
                          color: 'black',
                          '&.Mui-checked': {
                            color: 'primary.main',
                          },
                        }}
                      />
                    }
                    label={t('pep.funds.3')}
                    sx={{
                      '.MuiTypography-root': {
                        fontSize: '1.4rem', // Increases the label font size
                        lineHeight: '1.5', // Ensures proper line spacing
                        marginLeft: '10px',
                      },
                      alignItems: 'center', // Vertically aligns the label with the 3
                      marginTop: '10px',
                      marginLeft: '10px',
                    }}
                  />
                </div>
                <div style={{ marginBottom: '35px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fundsOption5}
                        onChange={handleFundsOption5}
                        color="primary"
                        sx={{
                          transform: 'scale(2)', // Increases the size of the checkbox
                          color: 'black',
                          '&.Mui-checked': {
                            color: 'primary.main',
                          },
                        }}
                      />
                    }
                    label={t('pep.funds.4')}
                    sx={{
                      '.MuiTypography-root': {
                        fontSize: '1.4rem', // Increases the label font size
                        lineHeight: '1.5', // Ensures proper line spacing
                        marginLeft: '10px',
                      },
                      alignItems: 'center', // Vertically aligns the label with the 3
                      marginTop: '10px',
                      marginLeft: '10px',
                    }}
                  />
                </div>
              </div>

              {/* Consent Section */}
              <div style={{ marginTop: '45px' }}>
                <Typography
                  variant="h6"
                  sx={{ marginBottom: '10px', fontSize: '22px' }}
                >
                  {translations.consent}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={consent1}
                      onChange={handleConsent1}
                      color="primary"
                      sx={{
                        transform: 'scale(2)', // Increases the size of the checkbox
                        color: 'black',
                        '&.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                    />
                  }
                  label={t('pep.consentStatements.0')}
                  sx={{
                    '.MuiTypography-root': {
                      fontSize: '1.2rem', // Increases the label font size
                      lineHeight: '1.5', // Ensures proper line spacing
                      marginLeft: '10px',
                    },
                    alignItems: 'center', // Vertically aligns the label with the 3
                    marginTop: '20px',
                    marginLeft: '10px',
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={consent2}
                      onChange={handleConsent2}
                      color="primary"
                      sx={{
                        transform: 'scale(2)', // Increases the size of the checkbox
                        color: 'black',
                        '&.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                    />
                  }
                  label={t('pep.consentStatements.1')}
                  sx={{
                    '.MuiTypography-root': {
                      fontSize: '1.2rem', // Increases the label font size
                      lineHeight: '1.5', // Ensures proper line spacing
                      marginLeft: '10px',
                    },
                    alignItems: 'center', // Vertically aligns the label with the 3
                    marginTop: '20px',
                    marginLeft: '10px',
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={consent3}
                      onChange={handleConsent3}
                      color="primary"
                      sx={{
                        transform: 'scale(2)', // Increases the size of the checkbox
                        color: 'black',
                        '&.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                    />
                  }
                  label={t('pep.consentStatements.2')}
                  sx={{
                    '.MuiTypography-root': {
                      fontSize: '1.2rem', // Increases the label font size
                      lineHeight: '1.5', // Ensures proper line spacing
                      marginLeft: '10px',
                    },
                    alignItems: 'center', // Vertically aligns the label with the 3
                    marginTop: '20px',
                    marginLeft: '10px',
                  }}
                />
              </div>
              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    handleConfirmPep();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.confirm')}
                </button>
              </div>
            </div>
          </div>
        </Grid>
      )}

      {showRisky && (
        <Grid
          item
          xs={12}
          sx={{
            padding: '5px',
            height: 'calc(100% - 25px)',
            width: '100%',
            marginTop: 0,
            marginLeft: 0,
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            color: 'white', // White text color
            overflow: 'auto',
          }}
        >
          <div
            style={{
              height: '100%',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                color: 'black',
                background: 'white',
                padding: '20px',
                borderRadius: '10px',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <h2 style={{ fontSize: '28px' }}>
                {t('sales.riskyCountryTitle')}
              </h2>
              <p style={{ fontSize: '22px' }}>{t('sales.riskyCountry')}</p>

              {/* Funds Section */}
              <div
                style={{
                  marginTop: '25px',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ marginBottom: '10px', fontSize: '22px' }}
                >
                  {translations.fundsQuestion}
                </Typography>
                <div style={{ marginBottom: '35px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fundsOption1}
                        onChange={handleFundsOption1}
                        color="primary"
                        sx={{
                          transform: 'scale(2)', // Increases the size of the checkbox
                          color: 'black',
                          '&.Mui-checked': {
                            color: 'primary.main',
                          },
                        }}
                      />
                    }
                    label={t('pep.funds.0')}
                    sx={{
                      '.MuiTypography-root': {
                        fontSize: '1.4rem', // Increases the label font size
                        lineHeight: '1.5', // Ensures proper line spacing
                        marginLeft: '10px',
                      },
                      alignItems: 'center', // Vertically aligns the label with the 3
                      marginTop: '10px',
                      marginLeft: '10px',
                    }}
                  />
                </div>
                <div style={{ marginBottom: '35px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fundsOption2}
                        onChange={handleFundsOption2}
                        color="primary"
                        sx={{
                          transform: 'scale(2)', // Increases the size of the checkbox
                          color: 'black',
                          '&.Mui-checked': {
                            color: 'primary.main',
                          },
                        }}
                      />
                    }
                    label={t('pep.funds.1')}
                    sx={{
                      '.MuiTypography-root': {
                        fontSize: '1.4rem', // Increases the label font size
                        lineHeight: '1.5', // Ensures proper line spacing
                        marginLeft: '10px',
                      },
                      alignItems: 'center', // Vertically aligns the label with the 3
                      marginTop: '10px',
                      marginLeft: '10px',
                    }}
                  />
                </div>
                <div style={{ marginBottom: '35px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fundsOption3}
                        onChange={handleFundsOption3}
                        color="primary"
                        sx={{
                          transform: 'scale(2)', // Increases the size of the checkbox
                          color: 'black',
                          '&.Mui-checked': {
                            color: 'primary.main',
                          },
                        }}
                      />
                    }
                    label={t('pep.funds.2')}
                    sx={{
                      '.MuiTypography-root': {
                        fontSize: '1.4rem', // Increases the label font size
                        lineHeight: '1.5', // Ensures proper line spacing
                        marginLeft: '10px',
                      },
                      alignItems: 'center', // Vertically aligns the label with the 3
                      marginTop: '10px',
                      marginLeft: '10px',
                    }}
                  />
                </div>
                <div style={{ marginBottom: '35px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fundsOption4}
                        onChange={handleFundsOption4}
                        color="primary"
                        sx={{
                          transform: 'scale(2)', // Increases the size of the checkbox
                          color: 'black',
                          '&.Mui-checked': {
                            color: 'primary.main',
                          },
                        }}
                      />
                    }
                    label={t('pep.funds.3')}
                    sx={{
                      '.MuiTypography-root': {
                        fontSize: '1.4rem', // Increases the label font size
                        lineHeight: '1.5', // Ensures proper line spacing
                        marginLeft: '10px',
                      },
                      alignItems: 'center', // Vertically aligns the label with the 3
                      marginTop: '10px',
                      marginLeft: '10px',
                    }}
                  />
                </div>
                <div style={{ marginBottom: '35px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fundsOption5}
                        onChange={handleFundsOption5}
                        color="primary"
                        sx={{
                          transform: 'scale(2)', // Increases the size of the checkbox
                          color: 'black',
                          '&.Mui-checked': {
                            color: 'primary.main',
                          },
                        }}
                      />
                    }
                    label={t('pep.funds.4')}
                    sx={{
                      '.MuiTypography-root': {
                        fontSize: '1.4rem', // Increases the label font size
                        lineHeight: '1.5', // Ensures proper line spacing
                        marginLeft: '10px',
                      },
                      alignItems: 'center', // Vertically aligns the label with the 3
                      marginTop: '10px',
                      marginLeft: '10px',
                    }}
                  />
                </div>
              </div>

              {/* Consent Section */}
              <div style={{ marginTop: '45px' }}>
                <Typography
                  variant="h6"
                  sx={{ marginBottom: '10px', fontSize: '22px' }}
                >
                  {translations.consent}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={consent1}
                      onChange={handleConsent1}
                      color="primary"
                      sx={{
                        transform: 'scale(2)', // Increases the size of the checkbox
                        color: 'black',
                        '&.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                    />
                  }
                  label={t('pep.consentStatements.0')}
                  sx={{
                    '.MuiTypography-root': {
                      fontSize: '1.2rem', // Increases the label font size
                      lineHeight: '1.5', // Ensures proper line spacing
                      marginLeft: '10px',
                    },
                    alignItems: 'center', // Vertically aligns the label with the 3
                    marginTop: '20px',
                    marginLeft: '10px',
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={consent2}
                      onChange={handleConsent2}
                      color="primary"
                      sx={{
                        transform: 'scale(2)', // Increases the size of the checkbox
                        color: 'black',
                        '&.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                    />
                  }
                  label={t('pep.consentStatements.1')}
                  sx={{
                    '.MuiTypography-root': {
                      fontSize: '1.2rem', // Increases the label font size
                      lineHeight: '1.5', // Ensures proper line spacing
                      marginLeft: '10px',
                    },
                    alignItems: 'center', // Vertically aligns the label with the 3
                    marginTop: '20px',
                    marginLeft: '10px',
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={consent3}
                      onChange={handleConsent3}
                      color="primary"
                      sx={{
                        transform: 'scale(2)', // Increases the size of the checkbox
                        color: 'black',
                        '&.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                    />
                  }
                  label={t('pep.consentStatements.2')}
                  sx={{
                    '.MuiTypography-root': {
                      fontSize: '1.2rem', // Increases the label font size
                      lineHeight: '1.5', // Ensures proper line spacing
                      marginLeft: '10px',
                    },
                    alignItems: 'center', // Vertically aligns the label with the 3
                    marginTop: '20px',
                    marginLeft: '10px',
                  }}
                />
              </div>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    handleConfirmRisky();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.confirm')}
                </button>
              </div>
            </div>
          </div>
        </Grid>
      )}

      {showCreatePincode && (
        <>
          <Grid
            item
            xs={12}
            sx={{
              padding: '10px',
              height: 'calc(100% - 55px)',
              width: '100%',
              marginTop: 0,
              marginLeft: 0,
              justifyContent: 'center',
              alignContent: 'center',
              display: 'flex',
              alignItems: 'center',
              color: 'white', // White text color
            }}
          >
            <Grid
              container
              flexDirection="row"
              sx={{
                height: '100%',
                width: '100%',
                marginTop: 0,
                marginLeft: 0,
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  padding: '10px',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '85px',
                  fontSize: '25px',
                  fontWeight: 'bold',
                  flexDirection: 'row',
                  marginTop: 0,
                  color: 'white', // White text color
                }}
              >
                <Grid
                  container
                  flexDirection="row"
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    marginTop: 0,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: '10px',
                      display: 'flex',
                      alignContent: 'center',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '75px',
                      fontSize: '19px',
                      fontWeight: 'bold',
                      flexDirection: 'row',
                      marginTop: 0,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '23px',
                        fontWeight: 'bold',
                        color: 'white', // White text color
                      }}
                    >
                      Velg ny PIN-kode for uttak (4 sifrer)
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  marginTop: 0,
                  padding: '10px',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <TextField
                  type="password"
                  value={amount}
                  sx={{
                    '& label': {
                      position: 'relative !important',
                      fontSize: '22px !important',
                      color: 'white !important',
                    },
                    '& label.Mui-focused': {
                      position: 'relative !important',
                      color: 'white !important',
                    },
                    '& input': {
                      color: 'white !important',
                      border: '3px solid white',
                      borderRadius: '4px',
                      height: '50px !important',
                      width: '275px !important',
                      fontSize: '40px !important',
                      textAlign: 'center',
                    },
                    '& input.Mui-focused': {
                      color: 'white !important',
                      border: '3px solid white',
                      borderRadius: '4px',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              width: '100%',
              maxWidth: '100% !important',
              height: '100%',
              backgroundColor: '#1b1c20',
            }}
          >
            <Grid
              container
              flexDirection="row"
              sx={{
                height: '180px',
                width: '100%',
                marginTop: 0,
                marginLeft: 0,
                padding: '10px',
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <CalculatorItem updateAmount={updateAmount} value={'1'} />
              <CalculatorItem updateAmount={updateAmount} value={'2'} />
              <CalculatorItem updateAmount={updateAmount} value={'3'} />
            </Grid>
            <Grid
              container
              flexDirection="row"
              sx={{
                height: '180px',
                width: '100%',
                marginTop: 0,
                marginLeft: 0,
                padding: '10px',
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <CalculatorItem updateAmount={updateAmount} value={'4'} />
              <CalculatorItem updateAmount={updateAmount} value={'5'} />
              <CalculatorItem updateAmount={updateAmount} value={'6'} />
            </Grid>
            <Grid
              container
              flexDirection="row"
              sx={{
                height: '180px',
                width: '100%',
                marginTop: 0,
                marginLeft: 0,
                padding: '10px',
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <CalculatorItem updateAmount={updateAmount} value={'7'} />
              <CalculatorItem updateAmount={updateAmount} value={'8'} />
              <CalculatorItem updateAmount={updateAmount} value={'9'} />
            </Grid>
            <Grid
              container
              flexDirection="row"
              sx={{
                height: '180px',
                width: '100%',
                marginTop: 0,
                marginLeft: 0,
                padding: '10px',
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'space-around',
                marginBottom: '20px',
              }}
            >
              <CalculatorItem updateAmount={updateAmount} value={'backspace'} />
              <CalculatorItem updateAmount={updateAmount} value={'0'} />
              <CalculatorItem
                updateAmount={updateAmount}
                value={'confirmNewPincode'}
              />
            </Grid>
          </Grid>
        </>
      )}

      {isPincode && (
        <>
          <Grid
            item
            xs={12}
            sx={{
              padding: '10px',
              height: 'calc(100% - 55px)',
              width: '100%',
              marginTop: 0,
              marginLeft: 0,
              justifyContent: 'center',
              alignContent: 'center',
              display: 'flex',
              alignItems: 'center',
              color: 'white', // White text color
            }}
          >
            <Grid
              container
              flexDirection="row"
              sx={{
                height: '100%',
                width: '100%',
                marginTop: 0,
                marginLeft: 0,
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  padding: '10px',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '85px',
                  fontSize: '25px',
                  fontWeight: 'bold',
                  flexDirection: 'row',
                  marginTop: 0,
                  color: 'white', // White text color
                }}
              >
                <Grid
                  container
                  flexDirection="row"
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    marginTop: 0,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: '10px',
                      display: 'flex',
                      alignContent: 'center',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '75px',
                      fontSize: '19px',
                      fontWeight: 'bold',
                      flexDirection: 'row',
                      marginTop: 0,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '23px',
                        fontWeight: 'bold',
                        color: 'white', // White text color
                      }}
                    >
                      Tast inn bekreftelseskoden din
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  marginTop: 0,
                  padding: '10px',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <TextField
                  type="password"
                  value={amount}
                  sx={{
                    '& label': {
                      position: 'relative !important',
                      fontSize: '22px !important',
                      color: 'white !important',
                    },
                    '& label.Mui-focused': {
                      position: 'relative !important',
                      color: 'white !important',
                    },
                    '& input': {
                      color: 'white !important',
                      border: '3px solid white',
                      borderRadius: '4px',
                      height: '50px !important',
                      width: '275px !important',
                      fontSize: '40px !important',
                      textAlign: 'center',
                    },
                    '& input.Mui-focused': {
                      color: 'white !important',
                      border: '3px solid white',
                      borderRadius: '4px',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              width: '100%',
              maxWidth: '100% !important',
              height: '100%',
              backgroundColor: '#1b1c20',
            }}
          >
            <Grid
              container
              flexDirection="row"
              sx={{
                height: '180px',
                width: '100%',
                marginTop: 0,
                marginLeft: 0,
                padding: '10px',
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <CalculatorItem updateAmount={updateAmount} value={'1'} />
              <CalculatorItem updateAmount={updateAmount} value={'2'} />
              <CalculatorItem updateAmount={updateAmount} value={'3'} />
            </Grid>
            <Grid
              container
              flexDirection="row"
              sx={{
                height: '180px',
                width: '100%',
                marginTop: 0,
                marginLeft: 0,
                padding: '10px',
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <CalculatorItem updateAmount={updateAmount} value={'4'} />
              <CalculatorItem updateAmount={updateAmount} value={'5'} />
              <CalculatorItem updateAmount={updateAmount} value={'6'} />
            </Grid>
            <Grid
              container
              flexDirection="row"
              sx={{
                height: '180px',
                width: '100%',
                marginTop: 0,
                marginLeft: 0,
                padding: '10px',
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <CalculatorItem updateAmount={updateAmount} value={'7'} />
              <CalculatorItem updateAmount={updateAmount} value={'8'} />
              <CalculatorItem updateAmount={updateAmount} value={'9'} />
            </Grid>
            <Grid
              container
              flexDirection="row"
              sx={{
                height: '180px',
                width: '100%',
                marginTop: 0,
                marginLeft: 0,
                padding: '10px',
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'space-around',
                marginBottom: '20px',
              }}
            >
              <CalculatorItem updateAmount={updateAmount} value={'backspace'} />
              <CalculatorItem updateAmount={updateAmount} value={'0'} />
              <CalculatorItem updateAmount={updateAmount} value={'confirm'} />
            </Grid>
          </Grid>
        </>
      )}

      {isStandBy && (
        <>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
              width: '100vw',
              marginTop: 0,
              backgroundColor: '#000',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: '10px',
                height: 'calc(100% - 75px)',
                width: '100%',
                marginTop: 0,
                marginLeft: 0,
                justifyContent: 'center',
                alignContent: 'center',
                display: 'flex',
                alignItems: 'center',
                color: 'white',
                position: 'relative',
              }}
            >
              <Box
                component="img"
                sx={{
                  height: '300px',
                  width: 'auto',
                  paddingTop: 0,
                  position: 'absolute',
                  top: `${position.top}px`,
                  left: `${position.left}px`,
                  transition: 'top 2.5s ease, left 2.5s ease', // Smooth transition
                }}
                alt=""
                src={ClubHouseLogo}
              />
            </Grid>
          </Container>
        </>
      )}

      {isSignature && (
        <>
          <Grid
            item
            xs={12}
            sx={{
              height: 'calc(100% - 75px)',
              width: '100%',
              marginTop: 0,
              marginLeft: 0,
              justifyContent: 'center',
              alignContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <canvas
              ref={canvasRef}
              width={540}
              height={825}
              style={{
                border: '1px solid #000000',
                background: 'white',
              }}
              onMouseDown={startDrawing}
              onMouseMove={draw}
              onMouseUp={stopDrawing}
              onMouseOut={stopDrawing}
              onTouchStart={startDrawing}
              onTouchMove={draw}
              onTouchEnd={stopDrawing}
              onTouchCancel={stopDrawing}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}
          ></Grid>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'row', // Ensures that items are in a row
              flexWrap: 'wrap', // Allows items to wrap when necessary
              justifyContent: 'center', // Center align items horizontally
              alignItems: 'center', // Center align items vertically
              width: '100%', // Set width to 100% of the viewport
              padding: '10px 0', // Add some padding top and bottom
            }}
          >
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              sx={{
                display: 'flex',
                justifyContent: 'center', // Center content within each grid
                alignItems: 'center', // Align items vertically
                padding: '10px', // Add padding around each button
              }}
            >
              <Button
                variant="contained"
                component="label"
                color="primary"
                sx={{
                  width: '200px',
                  height: '90px',
                  color: 'white',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  backgroundColor: '#009e82',
                }}
                onClick={() => {
                  sendCanvasAsBlob();
                }}
              >
                Fullfør
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10px',
              }}
            >
              <Button
                variant="contained"
                component="label"
                color="primary"
                sx={{
                  height: '90px',
                  width: '180px',
                  color: 'white',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  backgroundColor: '#fcad89',
                }}
                onClick={reset}
              >
                Nullstill
              </Button>
            </Grid>
          </Container>
        </>
      )}
    </Container>
  );
}

export default UserLogin;
