import React, { useContext, useState, useEffect, useRef } from 'react';
import { Grid, Button } from '@mui/material';
import { AppContext } from '../../../context/Context';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../assets/css/buttons.css';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { SocketContext } from '../../../context/SocketContext';
import toast from 'react-hot-toast';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  NextPlan as NextPlanIcon,
} from '@mui/icons-material/';

function SellScreenButtons({
  amount,
  sum,
  action,
  sortDescription,
  executeConfirm,
}) {
  const { t } = useTranslation();
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if (executeConfirm) {
      confirmButton();
    }
  }, [executeConfirm]);

  const {
    isWGEnabled,
    WGBingoID,
    updateTicketsTempOK,
    updateTicketsTempWG,
    updateIsLoadingScreen,
    OKBingoID,
    updateCurrentTicketOK,
    currentTicketOK,
    isOKBingoEnabled,
    updateIsDailyLimitSurpassed,
    updateIsMonthlyLimitSurpassed,
    updateIsLimitSurpassed,
    updateShowIsLimitSurpassed,
    updateMaxAllowed,
    updateShowIsLimitSurpassedTransaction,
    printer,
    currentTicketEBS,
    updateCurrentTicketEBS,
    currentTicketWG,
    updateCurrentTicketWG,
    updateTransactions,
    updateTransactionsArray,
    updateIsSellerOpen,
    modifyProvider,
    isMultiplier,
    updateIsMultiplier,
    updateIsMultiplier2,
    updateIsMultiplier4,
    updateIsMultiplier3,
    updateIsMultiplier5,
    updateIsMultiplier6,
    updateIsMultiplier7,
    updateIsMultiplier8,
    updateIsMultiplier9,
    updateIsMultiplier10,

    operator,
    session,
    hall,
    currentUser,
    userInfo,

    addTicketsCH,
    addMultipleTicketsCH,
    parentInfo,
    isTerminalBusy,
    updateIsTerminalBusy,
  } = useContext(AppContext);

  const isWGEnabledRef = useRef(isWGEnabled);
  useEffect(() => {
    isWGEnabledRef.current = isWGEnabled;
  }, [isWGEnabled]);

  const OKBingoIDRef = useRef(OKBingoID);
  useEffect(() => {
    OKBingoIDRef.current = OKBingoID;
  }, [OKBingoID]);

  const WGBingoIDRef = useRef(WGBingoID);
  useEffect(() => {
    WGBingoIDRef.current = WGBingoID;
  }, [WGBingoID]);

  const [newOKTicket, setNewOKTicket] = useState('');
  const newOKTicketRef = useRef(newOKTicket);
  useEffect(() => {
    newOKTicketRef.current = newOKTicket;
  }, [newOKTicket]);

  const [okTicketID, setOkTicketID] = useState(false);
  const okTicketIDRef = useRef(okTicketID);
  useEffect(() => {
    okTicketIDRef.current = okTicketID;
  }, [okTicketID]);

  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  const operatorRef = useRef(operator);
  useEffect(() => {
    operatorRef.current = operator;
  }, [operator]);

  const hallRef = useRef(hall);
  useEffect(() => {
    hallRef.current = hall;
  }, [hall]);

  const userInfoRef = useRef(userInfo);
  useEffect(() => {
    userInfoRef.current = userInfo;
  }, [userInfo]);

  const parentInfoRef = useRef(parentInfo);
  useEffect(() => {
    parentInfoRef.current = parentInfo;
  }, [parentInfo]);

  const amountRef = useRef(amount);
  useEffect(() => {
    amountRef.current = amount;
  }, [amount]);

  const printerRef = useRef(printer);
  useEffect(() => {
    printerRef.current = printer;
  }, [printer]);

  const isMultiplierRef = useRef(isMultiplier);
  useEffect(() => {
    isMultiplierRef.current = isMultiplier;
  }, [isMultiplier]);

  const actionRef = useRef(action);
  useEffect(() => {
    actionRef.current = action;
    if (actionRef.current === 'Fylle på' && sortDescription === 'CH') {
    }
    if (actionRef.current === 'Fylle på' && sortDescription === 'CH') {
    }
    if (actionRef.current === 'Fylle på' && sortDescription === 'CH') {
    }
  }, [action]);

  const sortDescriptionRef = useRef(sortDescription);
  useEffect(() => {
    sortDescriptionRef.current = sortDescription;
  }, [sortDescription]);

  useEffect(() => {
    const handleCashinExternal = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }

      if (data.maxAllowed) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        updateMaxAllowed(data.maxAllowed);
        updateShowIsLimitSurpassedTransaction(true);

        return;
      }

      if (data.maxAllowed === 0) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        if (data.isMonthly) {
          updateIsMonthlyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
        if (data.isDaily) {
          updateIsDailyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
      }

      var correctAmount = parseInt(amountRef.current);
      if (actionRef.current === 'Ut' || actionRef.current === 'Utbetaling') {
        correctAmount = parseInt(amountRef.current) * -1;
      }

      updateTransactions({
        name: actionRef.current + ' - ' + sortDescriptionRef.current,
        amount: correctAmount,
        //amount: data.balance,
        type: 'transaction',
      });

      if (parentInfo) {
        socket.emit('checkLimitStatus', parentInfo);
      } else {
        socket.emit('checkLimitStatus', userInfo);
      }

      setTimeout(() => {
        updateIsLoadingScreen(false);
        updateIsSellerOpen(false);
        updateIsTerminalBusy(false);
      }, 300);
    };
    const handleCashinAnon = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }

      if (data.maxAllowed) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);
        updateMaxAllowed(data.maxAllowed);
        updateShowIsLimitSurpassedTransaction(true);

        return;
      }

      if (data.maxAllowed === 0) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        if (data.isMonthly) {
          updateIsMonthlyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
        if (data.isDaily) {
          updateIsDailyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
      }

      if (parentInfo) {
        socket.emit('checkLimitStatus', parentInfo);
      } else {
        socket.emit('checkLimitStatus', userInfo);
      }
      updateTransactions({
        name: actionRef.current + ' - ' + sortDescriptionRef.current,
        //amount: parseInt(amount),
        amount: parseInt(amountRef.current),
        type: 'transaction',
        ticketData: data,
      });

      var ticketTemp = {
        userID: data.player_id,
        user7Pincode: data.pin,
        username: data.username,
      };
      addTicketsCH(ticketTemp);

      updateIsMultiplier(1);
      updateIsMultiplier2(false);
      updateIsMultiplier3(false);
      updateIsMultiplier4(false);
      updateIsMultiplier5(false);
      updateIsMultiplier6(false);
      updateIsMultiplier7(false);
      updateIsMultiplier8(false);
      updateIsMultiplier9(false);
      updateIsMultiplier10(false);
      updateIsTerminalBusy(false);
      updateIsLoadingScreen(false);
      updateIsSellerOpen(false);
    };
    const handleCashinRefill = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }

      if (data.maxAllowed) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);
        updateMaxAllowed(data.maxAllowed);
        updateShowIsLimitSurpassedTransaction(true);

        return;
      }

      if (data.maxAllowed === 0) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        if (data.isMonthly) {
          updateIsMonthlyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
        if (data.isDaily) {
          updateIsDailyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
      }

      if (parentInfo) {
        socket.emit('checkLimitStatus', parentInfo);
      } else {
        socket.emit('checkLimitStatus', userInfo);
      }

      updateTransactions({
        name: actionRef.current + ' - ' + sortDescriptionRef.current,
        //amount: parseInt(amount),
        amount: parseInt(amountRef.current),
        type: 'transaction',
        ticketData: data,
      });

      updateIsLoadingScreen(false);
      updateIsSellerOpen(false);
      updateIsTerminalBusy(false);
    };
    const handleCashinAnonMulti = async (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }

      if (data.maxAllowed) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);
        updateMaxAllowed(data.maxAllowed);
        updateShowIsLimitSurpassedTransaction(true);

        return;
      }

      if (data.maxAllowed === 0) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        if (data.isMonthly) {
          updateIsMonthlyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
        if (data.isDaily) {
          updateIsDailyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
      }

      if (parentInfo) {
        socket.emit('checkLimitStatus', parentInfo);
      } else {
        socket.emit('checkLimitStatus', userInfo);
      }

      const updateTransListState = await updateTransactionsList(data);
      updateTransactionsArray(updateTransListState);

      updateIsMultiplier(1);
      updateIsMultiplier2(false);
      updateIsMultiplier3(false);
      updateIsMultiplier4(false);
      updateIsMultiplier5(false);
      updateIsMultiplier6(false);
      updateIsMultiplier7(false);
      updateIsMultiplier8(false);
      updateIsMultiplier9(false);
      updateIsMultiplier10(false);
      updateIsTerminalBusy(false);
      addMultipleTicketsCH(data);

      updateIsLoadingScreen(false);
      updateIsSellerOpen(false);
    };
    const handleCashout = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        //deleteTransactions(transactions[transactions.length - 1]);
        return;
      }

      if (parentInfo) {
        socket.emit('checkLimitStatus', parentInfo);
      } else {
        socket.emit('checkLimitStatus', userInfo);
      }

      updateTransactions({
        name: actionRef.current + ' - ' + sortDescriptionRef.current,
        //amount: parseInt(amount),
        amount: data.amount * -1,
        type: 'transaction',
        ticketData: data,
      });

      updateIsLoadingScreen(false);
      updateIsTerminalBusy(false);
      updateIsSellerOpen(false);
    };

    const handleCashinEBS = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }
      if (parentInfo) {
        socket.emit('checkLimitStatus', parentInfo);
      } else {
        socket.emit('checkLimitStatus', userInfo);
      }

      updateTransactions({
        name: actionRef.current + ' - ' + sortDescriptionRef.current,
        //amount: parseInt(amount),
        amount: parseInt(amountRef.current),
        type: 'transaction',
        ticketData: data,
      });

      updateCurrentTicketEBS(false);
      updateIsSellerOpen(false);
      updateIsTerminalBusy(false);
    };

    const handleCashoutEBS = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }
      updateTransactions({
        name: actionRef.current + ' - ' + sortDescriptionRef.current,
        //amount: parseInt(amount),
        amount: parseInt(amountRef.current),
        type: 'transaction',
        ticketData: data,
      });

      updateCurrentTicketEBS(false);
      updateIsSellerOpen(false);
      updateIsTerminalBusy(false);
      updateIsLoadingScreen(false);
    };

    const handleRefillEBS = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }
      updateTransactions({
        name: actionRef.current + ' - ' + sortDescriptionRef.current,
        //amount: parseInt(amount),
        amount: parseInt(amountRef.current),
        type: 'transaction',
        ticketData: data,
      });

      updateCurrentTicketEBS(false);
      updateIsSellerOpen(false);
      updateIsTerminalBusy(false);
      updateIsLoadingScreen(false);
    };

    const handleCashinWG = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }
      if (parentInfo) {
        socket.emit('checkLimitStatus', parentInfo);
      } else {
        socket.emit('checkLimitStatus', userInfo);
      }

      updateTransactions({
        name: actionRef.current + ' - ' + sortDescriptionRef.current,
        //amount: parseInt(amount),
        amount: parseInt(amountRef.current),
        type: 'transaction',
        ticketData: data,
      });
      updateTicketsTempWG(data);
      updateCurrentTicketWG(false);
      updateIsSellerOpen(false);
      updateIsTerminalBusy(false);
    };

    const handleCashoutWG = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }
      updateTransactions({
        name: actionRef.current + ' - ' + sortDescriptionRef.current,
        //amount: parseInt(amount),
        amount: parseInt(amountRef.current) * -1,
        type: 'transaction',
        ticketData: data,
      });

      updateCurrentTicketWG(false);
      updateIsSellerOpen(false);
      updateIsTerminalBusy(false);
      updateIsLoadingScreen(false);
    };

    const handleRefillWG = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }
      updateTransactions({
        name: actionRef.current + ' - ' + sortDescriptionRef.current,
        //amount: parseInt(amount),
        amount: parseInt(amountRef.current),
        type: 'transaction',
        ticketData: data,
      });

      updateCurrentTicketWG(false);
      updateIsSellerOpen(false);
      updateIsTerminalBusy(false);
      updateIsLoadingScreen(false);
    };

    const handleCashinOK = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }
      setOkTicketID(data.ticketID);
    };

    const handleBroadcastCashinOK = (data) => {
      if (data.errorNumber !== '') {
        toast.error(data.errorDescription);
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }
      setNewOKTicket(data.ticketNumber);
      updateTicketsTempOK(data);

      if (data.ticketID === okTicketIDRef.current) {
        socket.emit('registerTransactionCashinOK', {
          operator: operatorRef.current,
          bingoName: hallRef.current,
          sessionID: sessionRef.current,
          userInfo: parentInfoRef.current
            ? parentInfoRef.current
            : userInfoRef.current,
          amount: parseInt(amountRef.current),
          provider: sortDescriptionRef.current,
          description: actionRef.current,
          okID: data.ticketNumber,
          action: 'registerTransactionCashinOK',
          printer: printerRef.current,
          parameter: data,
          OKBingoID: OKBingoIDRef.current,
        });
      }
    };

    const handleRegisterTransactionCashinOK = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }

      if (data.maxAllowed) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        updateMaxAllowed(data.maxAllowed);
        updateShowIsLimitSurpassedTransaction(true);

        return;
      }

      if (data.maxAllowed === 0) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        if (data.isMonthly) {
          updateIsMonthlyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
        if (data.isDaily) {
          updateIsDailyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
      }

      if (parentInfo) {
        socket.emit('checkLimitStatus', parentInfo);
      } else {
        socket.emit('checkLimitStatus', userInfo);
      }

      updateTransactions({
        name: actionRef.current + ' - ' + sortDescriptionRef.current,
        amount: parseInt(amountRef.current),
        type: 'transaction',
        ticketData: data,
        ticketID: newOKTicketRef.current,
      });

      updateCurrentTicketOK(false);

      setTimeout(() => {
        updateIsLoadingScreen(false);
        updateIsSellerOpen(false);
        updateIsTerminalBusy(false);
      }, 300);
    };

    const handleCashoutOK = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }
      setOkTicketID(data.ticketID);
    };

    const handleBroadcastCashoutOK = (data) => {
      if (data.errorNumber !== '') {
        toast.error(data.errorDescription);
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }

      if (data.ticketID === okTicketIDRef.current) {
        socket.emit('registerTransactionCashoutOK', {
          operator: operatorRef.current,
          bingoName: hallRef.current,
          sessionID: sessionRef.current,
          userInfo: parentInfoRef.current
            ? parentInfoRef.current
            : userInfoRef.current,
          amount: parseInt(data.amount),
          provider: sortDescriptionRef.current,
          description: actionRef.current,
          okID: data.ticketNumber,
          action: 'registerTransactionCashoutOK',
          printer: printerRef.current,
          parameter: data,
          OKBingoID: OKBingoIDRef.current,
        });
      }
    };

    const handleRegisterTransactionCashoutOK = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }

      if (data.maxAllowed) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);
        updateMaxAllowed(data.maxAllowed);
        updateShowIsLimitSurpassedTransaction(true);

        return;
      }

      if (data.maxAllowed === 0) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        if (data.isMonthly) {
          updateIsMonthlyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
        if (data.isDaily) {
          updateIsDailyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
      }

      if (parentInfo) {
        socket.emit('checkLimitStatus', parentInfo);
      } else {
        socket.emit('checkLimitStatus', userInfo);
      }

      updateTransactions({
        name: actionRef.current + ' - ' + sortDescriptionRef.current,
        amount: parseInt(amountRef.current) * -1,
        type: 'transaction',
        ticketData: data,
      });

      updateCurrentTicketOK(false);

      setTimeout(() => {
        updateIsLoadingScreen(false);
        updateIsSellerOpen(false);
        updateIsTerminalBusy(false);
      }, 300);
    };

    const handleRefillOK = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }
      setOkTicketID(data.ticketID);
    };

    const handleBroadcastRefillOK = (data) => {
      if (data.errorNumber !== '') {
        toast.error(data.errorDescription);
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);
        return;
      }

      if (data.ticketID === okTicketIDRef.current) {
        socket.emit('registerTransactionRefillOK', {
          operator: operatorRef.current,
          bingoName: hallRef.current,
          sessionID: sessionRef.current,
          userInfo: parentInfoRef.current
            ? parentInfoRef.current
            : userInfoRef.current,
          amount: parseInt(amountRef.current),
          provider: sortDescriptionRef.current,
          description: actionRef.current,
          okID: data.ticketNumber,
          action: 'registerTransactionRefillOK',
          printer: printerRef.current,
          parameter: data,
          OKBingoID: OKBingoIDRef.current,
        });
      }
    };

    const handleRegisterTransactionRefillOK = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);
        return;
      }

      if (data.maxAllowed) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);
        updateMaxAllowed(data.maxAllowed);
        updateShowIsLimitSurpassedTransaction(true);

        return;
      }

      if (data.maxAllowed === 0) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        if (data.isMonthly) {
          updateIsMonthlyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
        if (data.isDaily) {
          updateIsDailyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
      }

      if (parentInfo) {
        socket.emit('checkLimitStatus', parentInfo);
      } else {
        socket.emit('checkLimitStatus', userInfo);
      }

      updateTransactions({
        name: actionRef.current + ' - ' + sortDescriptionRef.current,
        amount: parseInt(amountRef.current),
        type: 'transaction',
        ticketData: data,
      });

      updateCurrentTicketOK(false);

      setTimeout(() => {
        updateIsLoadingScreen(false);
        updateIsSellerOpen(false);
        updateIsTerminalBusy(false);
      }, 300);
    };

    socket.on('cashinExternal', handleCashinExternal);
    socket.on('cashinAnon', handleCashinAnon);
    socket.on('cashinAnonMulti', handleCashinAnonMulti);
    socket.on('cashinRefill', handleCashinRefill);
    socket.on('cashoutTicket', handleCashout);
    socket.on('cashinEBS', handleCashinEBS);
    socket.on('cashoutEBS', handleCashoutEBS);
    socket.on('refillEBS', handleRefillEBS);

    socket.on('cashinWG', handleCashinWG);
    socket.on('cashoutWG', handleCashoutWG);
    socket.on('refillWG', handleRefillWG);

    socket.on('cashinOK', handleCashinOK);
    socket.on('broadcastCashinOK', handleBroadcastCashinOK);
    socket.on('registerTransactionCashinOK', handleRegisterTransactionCashinOK);

    socket.on('cashoutOK', handleCashoutOK);
    socket.on('broadcastCashoutOK', handleBroadcastCashoutOK);
    socket.on(
      'registerTransactionCashoutOK',
      handleRegisterTransactionCashoutOK
    );

    socket.on('refillOK', handleRefillOK);
    socket.on('broadcastRefillOK', handleBroadcastRefillOK);
    socket.on('registerTransactionRefillOK', handleRegisterTransactionRefillOK);

    return () => {
      socket.off('cashinExternal', handleCashinExternal);
      socket.off('cashinAnon', handleCashinAnon);
      socket.off('cashinAnonMulti', handleCashinAnonMulti);
      socket.off('cashinRefill', handleCashinRefill);
      socket.off('cashoutTicket', handleCashout);
      socket.off('cashinEBS', handleCashinEBS);
      socket.off('cashoutEBS', handleCashoutEBS);
      socket.off('refillEBS', handleRefillEBS);

      socket.off('cashinWG', handleCashinWG);
      socket.off('cashoutWG', handleCashoutWG);
      socket.off('refillWG', handleRefillWG);

      socket.off('cashinOK', handleCashinOK);
      socket.off('broadcastCashinOK', handleBroadcastCashinOK);
      socket.off(
        'registerTransactionCashinOK',
        handleRegisterTransactionCashinOK
      );

      socket.off('cashoutOK', handleCashoutOK);
      socket.off('broadcastCashoutOK', handleBroadcastCashoutOK);
      socket.off(
        'registerTransactionCashoutOK',
        handleRegisterTransactionCashoutOK
      );

      socket.off('refillOK', handleRefillOK);
      socket.off('broadcastRefillOK', handleBroadcastRefillOK);
      socket.off(
        'registerTransactionRefillOK',
        handleRegisterTransactionRefillOK
      );
    };
  }, [socket]);

  //Confirm button for OK and WG
  const confirmTransactionExternalProvider = () => {
    var confirmMessage = t('sales.confirmOK');
    if (
      sortDescription !== 'OK D.' &&
      sortDescription !== 'WG' &&
      sortDescription !== 'NT'
    ) {
      endTransaction();
      return;
    }
    switch (sortDescription) {
      case 'OK D.':
        confirmMessage = t('sales.confirmOK');
        break;
      case 'WG':
        confirmMessage = t('sales.confirmWG');
        break;
      case 'NT':
        confirmMessage = t('sales.confirmNT');
        break;
      default:
        break;
    }

    confirmAlert({
      title: confirmMessage,
      message: '',
      buttons: [
        {
          label: t('sales.confirm'),
          onClick: () => {
            endTransaction();
          },
          className: 'btn-custom-yes',
        },

        {
          label: t('sales.cancel'),
          onClick: () => {},
          className: 'btn-custom-no',
        },
      ],
    });
  };

  //Finish the transaction
  const endTransaction = async () => {
    if (!sum) {
      amount = parseInt(amount) * -1;
    }

    //*EBS ACTIONS
    if (sortDescription === 'EBS' && action === 'Ny Billett') {
      socket.emit('cashinEBS', {
        operator: operator,
        bingoName: hall,
        sessionID: sessionRef.current,
        userInfo: parentInfo ? parentInfo : userInfo,
        amount: parseInt(amountRef.current),
        provider: sortDescription,
        description: action,
        action: 'cashinEBS',
        printer: printer,
      });
      return;
    }

    if (sortDescription === 'EBS' && action === 'Utbetaling') {
      socket.emit('cashoutEBS', {
        operator: operator,
        bingoName: hall,
        sessionID: sessionRef.current,
        userInfo: parentInfo ? parentInfo : userInfo,
        amount: parseInt(amountRef.current),
        provider: sortDescription,
        description: action,
        ebsID: currentTicketEBS.ebsID,
        action: 'cashoutEBS',
        printer: printer,
      });
      return;
    }

    if (sortDescription === 'EBS' && action === 'Fylle på') {
      socket.emit('refillEBS', {
        operator: operator,
        bingoName: hall,
        sessionID: sessionRef.current,
        userInfo: parentInfo ? parentInfo : userInfo,
        amount: parseInt(amountRef.current),
        provider: sortDescription,
        description: action,
        ebsID: currentTicketEBS.ebsID,
        action: 'refillEBS',
        printer: printer,
      });
      return;
    }

    //*WG ACTIONS
    if (
      sortDescription === 'WG' &&
      action === 'Ny Billett' &&
      isWGEnabledRef.current
    ) {
      socket.emit('cashinWG', {
        operator: operator,
        bingoName: hall,
        sessionID: sessionRef.current,
        userInfo: parentInfo ? parentInfo : userInfo,
        amount: parseInt(amountRef.current),
        provider: sortDescription,
        description: action,
        bingoID: WGBingoIDRef.current,
        action: 'cashinWG',
        printer: printer,
      });
      return;
    }

    if (
      sortDescription === 'WG' &&
      action === 'Utbetaling' &&
      isWGEnabledRef.current
    ) {
      socket.emit('cashoutWG', {
        operator: operator,
        bingoName: hall,
        sessionID: sessionRef.current,
        userInfo: parentInfo ? parentInfo : userInfo,
        amount: parseInt(amountRef.current),
        provider: sortDescription,
        description: action,
        wgID: currentTicketWG.wgID,
        bingoID: WGBingoIDRef.current,
        action: 'cashoutWG',
        printer: printer,
      });
      return;
    }

    if (
      sortDescription === 'WG' &&
      action === 'Fylle på' &&
      isWGEnabledRef.current
    ) {
      socket.emit('refillWG', {
        operator: operator,
        bingoName: hall,
        sessionID: sessionRef.current,
        userInfo: parentInfo ? parentInfo : userInfo,
        amount: parseInt(amountRef.current),
        provider: sortDescription,
        description: action,
        wgID: currentTicketWG.wgID,
        bingoID: WGBingoIDRef.current,
        action: 'refillWG',
        printer: printer,
      });
      return;
    }

    //*OK.D ACTIONS
    if (
      sortDescription === 'OK D.' &&
      action === 'Ny Billett' &&
      isOKBingoEnabled
    ) {
      socket.emit('cashinOK', {
        operator: operator,
        bingoName: hall,
        sessionID: sessionRef.current,
        userInfo: parentInfo ? parentInfo : userInfo,
        amount: parseInt(amountRef.current),
        provider: sortDescription,
        description: action,
        action: 'cashinOK',
        printer: printer,
        OKBingoID: OKBingoIDRef.current,
      });
      updateIsLoadingScreen(true);
      return;
    }

    if (
      sortDescription === 'OK D.' &&
      action === 'Utbetaling' &&
      isOKBingoEnabled
    ) {
      socket.emit('cashoutOK', {
        operator: operator,
        bingoName: hall,
        sessionID: sessionRef.current,
        userInfo: parentInfo ? parentInfo : userInfo,
        amount: parseInt(amountRef.current),
        provider: sortDescription,
        description: action,
        okID: currentTicketOK.okID,
        action: 'cashoutOK',
        printer: printer,
        OKBingoID: OKBingoIDRef.current,
      });
      updateIsLoadingScreen(true);
      return;
    }

    if (
      sortDescription === 'OK D.' &&
      action === 'Fylle på' &&
      isOKBingoEnabled
    ) {
      socket.emit('refillOK', {
        operator: operator,
        bingoName: hall,
        sessionID: sessionRef.current,
        userInfo: parentInfo ? parentInfo : userInfo,
        amount: parseInt(amountRef.current),
        provider: sortDescription,
        description: action,
        okID: currentTicketOK.okID,
        action: 'refillOK',
        printer: printer,
        OKBingoID: OKBingoIDRef.current,
      });
      updateIsLoadingScreen(true);
      return;
    }

    //*CH ACTIONS AND EXTERNAL PROVIDERS
    //Modal and confirm need here
    if (isMultiplierRef.current === 1) {
      //Add the new transaction to the list
      /*
      updateTransactions({
        name: action + " - " + sortDescription,
        amount: parseInt(amount),
        type: "transaction",
      });
      */
      //Ask for new anon child ticket if is CH
      if (action === 'Ny Billett' && sortDescription === 'CH') {
        var dataNewTicket = {
          name: actionRef.current + ' - ' + sortDescriptionRef.current,
          amount: parseInt(amountRef.current),
          type: 'transaction',
        };

        var data = {
          transactions: [],
          operator: operator,
          bingoName: hall,
          sessionID: session,
          provider: sortDescription,
          description: action,
          amount: parseInt(amount),
          user7Pincode: !parentInfo ? currentUser : parentInfo['7 digit PIN'],
          userInfo: !parentInfo ? userInfo : parentInfo,
          isChild: true,
          usePrinter: false,
          useSMS: false,
          printBarcode: false,
          qrMessage: false,
          session: session,
          action: 'cashinAnon',
        };

        data.transactions.push(dataNewTicket);
        updateIsLoadingScreen(true);
        socket.emit('cashinAnon', data);
      } else if (actionRef.current === 'Fylle på' && sortDescription === 'CH') {
        var dataNewTicket = {
          name: actionRef.current + ' - ' + sortDescriptionRef.current,
          amount: parseInt(amountRef.current),
          type: 'transaction',
        };

        var data = {
          transactions: [],
          operator: operator,
          bingoName: hall,
          sessionID: session,
          provider: sortDescription,
          description: action,
          amount: parseInt(amount),
          playerID: userInfo.id,
          isChild: false,
          usePrinter: false,
          useSMS: false,
          printBarcode: false,
          qrMessage: false,
          session: session,
          action: 'cashinRefill',
          user7Pincode: !parentInfo ? currentUser : parentInfo['7 digit PIN'],
          userInfo: !parentInfo ? userInfo : parentInfo,
          ticketID: userInfo.id,
          ticketUsername: userInfo.username,
          ticket7Pincode: userInfo['7 digit PIN'],
        };

        data.transactions.push(dataNewTicket);
        updateIsLoadingScreen(true);
        socket.emit('cashinRefill', data);
      } else if (sortDescription !== 'CH') {
        var dataNewTicket = {
          name: actionRef.current + ' - ' + sortDescriptionRef.current,
          amount: parseInt(amountRef.current),
          type: 'transaction',
        };

        var data = {
          transactions: [],
          operator: operator,
          bingoName: hall,
          sessionID: session,
          provider: sortDescription,
          description: action,
          amount: parseInt(amount),

          isChild: true,
          usePrinter: false,
          useSMS: false,
          printBarcode: false,
          qrMessage: false,
          session: session,
          ticketID: 0,
          ticketUsername: '',
          ticket7Pincode: '',
          user7Pincode: !parentInfo ? currentUser : parentInfo['7 digit PIN'],
          userInfo: !parentInfo ? userInfo : parentInfo,

          action: 'cashinExternal',
        };

        data.transactions.push(dataNewTicket);
        updateIsLoadingScreen(true);
        socket.emit('cashinExternal', data);
      }

      if (action === 'Utbetaling' && sortDescription === 'CH') {
        var dataNewTicket = {
          name: actionRef.current + ' - ' + sortDescriptionRef.current,
          amount: parseInt(amountRef.current),
          type: 'transaction',
        };

        var data = {
          transactions: [],
          operator: operator,
          bingoName: hall,
          sessionID: session,
          provider: sortDescription,
          description: action,
          amount: parseInt(amount),
          user7Pincode: parentInfo['7 digit PIN'],
          userInfo: parentInfo,
          isChild: true,
          usePrinter: false,
          useSMS: false,
          printBarcode: false,
          qrMessage: false,
          session: session,
          playerID: userInfo.id,
          cashoutType: 'cash_from_wallet',
          ticketID: userInfo.id,
          ticketUsername: userInfo.username,
          ticket7Pincode: currentUser,

          action: 'cashoutTicket',
        };

        data.transactions.push(dataNewTicket);
        updateIsLoadingScreen(true);
        socket.emit('cashoutTicket', data);

        return;
      }

      resetMultipliers();
    } else {
      //Multiple CH TICKETS
      updateIsTerminalBusy(true);
      const updateTransSocket = await updateTransactionsSocket(isMultiplier);
      updateIsLoadingScreen(true);

      socket.emit('cashinAnonMulti', updateTransSocket);
      /*
      const updateTransListState = await updateTransactionsList(isMultiplier);
      updateTransactionsArray(updateTransListState);
      
      */
      /*
      updateIsTerminalBusy(true);
      const updateTransSocket = await updateTransactionsSocket(isMultiplier);
      */
    }
  };

  const resetMultipliers = () => {
    //Block the other provider
    if (
      action === 'Ny Billet' &&
      (sortDescription === 'WG' || sortDescription === 'OK D.')
    ) {
      modifyProvider(sortDescription.substring(0, 2), true);
    }

    updateIsMultiplier(1);
    updateIsMultiplier2(false);
    updateIsMultiplier3(false);
    updateIsMultiplier4(false);
    updateIsMultiplier5(false);
    updateIsMultiplier6(false);
    updateIsMultiplier7(false);
    updateIsMultiplier8(false);
    updateIsMultiplier9(false);
    updateIsMultiplier10(false);
  };

  //Update the transaction shopping cart
  const updateTransactionsList = async (newTickets) => {
    var data = [];
    for (let i = 0; i < newTickets.length; i++) {
      data = [
        ...data,
        {
          name: actionRef.current + ' - ' + sortDescriptionRef.current,
          amount: parseInt(amountRef.current),
          type: 'transaction',
          ticketData: newTickets[i],
        },
      ];
    }
    return data;
  };

  //Update the transaction data to send via socket
  const updateTransactionsSocket = async (timesMultiplier) => {
    var data = [];
    for (let i = 0; i < timesMultiplier; i++) {
      var dataNewTicket = {
        name: actionRef.current + ' - ' + sortDescriptionRef.current,
        amount: parseInt(amountRef.current),
        type: 'transaction',
      };

      var transNewTicket = [];
      transNewTicket.push(dataNewTicket);

      data.push({
        transactions: transNewTicket,
        operator: operator,
        bingoName: hall,
        sessionID: session,
        provider: sortDescription,
        description: action,
        amount: parseInt(amount),
        isChild: true,
        usePrinter: false,
        useSMS: false,
        printBarcode: false,
        qrMessage: false,
        session: session,
        action: 'cashinAnon',
        user7Pincode: !parentInfo ? currentUser : parentInfo['7 digit PIN'],
        userInfo: !parentInfo ? userInfo : parentInfo,
      });
    }
    return data;
  };

  //* BUTTONS FUNCTION
  const confirmButton = () => {
    if (isTerminalBusy || amount === '0' || amount === 0) {
      return;
    }

    //Confirm action in OK/WG cashier
    if (action + ' - ' + sortDescription === 'Ny Billett - NT') {
      confirmTransactionExternalProvider();
      return;
    }

    if (
      (action + ' - ' + sortDescription === 'Ny Billett - WG' ||
        action + ' - ' + sortDescription === 'Fylle på - WG') &&
      !isWGEnabled
    ) {
      confirmTransactionExternalProvider();
      return;
    }

    if (
      (action + ' - ' + sortDescription === 'Ny Billett - OK D.' ||
        action + ' - ' + sortDescription === 'Fylle på - OK D.') &&
      !isOKBingoEnabled
    ) {
      confirmTransactionExternalProvider();
      return;
    }

    //End transaction
    if (parseInt(amount) > 0) {
      updateIsTerminalBusy(true);

      endTransaction();
    }
  };

  const cancelButton = () => {
    if (isTerminalBusy) {
      return;
    }
    updateIsSellerOpen(false);
    updateIsMultiplier(1);
    updateIsMultiplier2(false);
    updateIsMultiplier3(false);
    updateIsMultiplier4(false);
    updateIsMultiplier5(false);
    updateIsMultiplier6(false);
    updateIsMultiplier7(false);
    updateIsMultiplier8(false);
    updateIsMultiplier9(false);
    updateIsMultiplier10(false);
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        width: '100%',
        maxWidth: '100% !important',
        height: '100%',
      }}
    >
      <Grid
        container
        flexDirection="row"
        sx={{
          height: '125px',
          width: '100%',
          marginTop: 0,
          marginLeft: 0,
          padding: '10px',
        }}
      >
        {/*CONFIRM BUTTON*/}
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            component="label"
            sx={{
              width: '200px',
              height: '90px',
              color: 'white',
              fontWeight: 'bold',
              backgroundColor: '#009f83',
              textTransform: 'uppercase',
              opacity:
                amount === '0' || amount === 0 || isTerminalBusy ? '0.25' : '1',
            }}
            onClick={() => {
              confirmButton();
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px', // Space between icon and text
            }}
          >
            <CheckCircleIcon style={{ fontSize: '1.2em' }} />

            {t('sales.confirm')}
          </Button>
        </Grid>

        {/*CANCEL BUTTON*/}
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            component="label"
            sx={{
              width: '200px',
              height: '90px',
              backgroundColor: '#fc86c1',
              color: 'white',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              opacity: isTerminalBusy ? '0.25' : '1',
            }}
            onClick={() => {
              cancelButton();
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px', // Space between icon and text
            }}
          >
            <CancelIcon style={{ fontSize: '1.2em' }} />

            {t('sales.cancel')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SellScreenButtons;
