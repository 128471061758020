export const TRANSLATIONS_EN = {
  login: {
    username: 'Username',
    password: 'Password',
    login: 'Login',
  },
  header: {
    sales: 'Sales',
    newMember: 'New user',
    searchMember: 'Search user',
    onlinePayment: 'Online payment',
    onlinePaymentHistory: 'Online payment history',
    report: 'Report',
    playerWithBalanceReport: 'Player with balance report',
    adminReport: 'Admin Report',
    openTickets: 'Tickets Today',
    ticketHistoric: 'Ticket Historic',
    recent: 'Recent sales',
    endShift: 'End shift',
    endShiftMessage: 'Do you want to end your shift?',
    specialGame: 'Special game',
    specialGameAvailable:
      'Special game is about to started, pay attention to the payment',
    specialGameSubtitle: 'Write the amount of the prize',
    specialGameMessage: 'This will change the prize of the last game to',
    specialGameMessageConfirm: 'The payment has been done successfully',
    specialGameMessageConfirmNoPrize:
      'The special game has been closed without winners',
    specialGameMessageError: 'There is not any open special game',
    lastTransactions: 'Last trans.',
    lastCardTransactions: 'Card trans.',
    printLastTrans: 'Print last sale',
    userLimits: 'User limits',
  },
  sales: {
    transactions: 'Transactions',
    printAction: 'Print',
    scanMessage: 'Scan or look for an user, or sell to an anonymous user',
    search: 'Search for an user',
    searchMemberPin: 'Search by pin',
    searchMemberUsername: 'Search by username',
    searchMemberPhonenumber: 'Search by phone number',
    autoSearch: 'Auto search',
    searchByFirstname: 'Search by first name',
    searchByLastname: 'Search by last name',
    searchBySSNID: 'Search by SSN ID',
    deposit: 'Deposit',
    depositSubtitle: 'To player account',
    withdraw: 'Withdraw',
    withdrawSubtitle: 'From player account',
    transfer: 'Bank Transfer',
    transferSubtitle: 'To bank account',
    newTicket: 'New ticket',
    payout: 'Payout',
    scan: 'Scan',
    refill: 'Refill',
    product: 'New product',
    cash: 'Cash',
    card: 'Card',
    spillerkonto: 'Player account',
    printMovement: 'Account Statement',
    saldoUserAccount: ' User balance: ',
    newCardMessage:
      'This card is not register, do you want to create a new user?',
    paymentCard: 'Finish this shop and pay by card?',
    paymentCash: 'Finish this shop and pay by cash?',
    yes: 'Yes',
    no: 'No',
    part: 'Split',
    partialAmount: 'Choose amount',
    confirm: 'Confirm',
    cancel: 'Cancel',
    cardPaymentItem: 'Cash withdrawal',
    confirmWG:
      'To continue, you need first to finish the operation in the Win Games cashier.',
    confirmOK:
      'To continue, you need first to finish the operation in the OK Databingo cashier.',
    confirmNT:
      'To continue, you need first to finish the operation in the Norsk Tipping cashier.',
    saldoUserAccount: ' Saldo på spillerkonto: ',

    total: 'Total',
    payCard: 'Payed by Card',
    payCash: 'Payed by Cash',
    payAccount: 'Payed by Account',
    change: 'Change',
    remaining: 'Remaining',

    paymentCardScreen: 'Payment by card',
    paymentCashScreen: 'Payment by cash',
    paymentFullCard: 'Pay total',
    paymentFullCash: 'Pay total',
    amount: 'Amount',
    endShop: 'Do you confirm the transaction is done?',
    endShopByCard:
      'Do you confirm the transaction is done? After confirm it, wait for the user payment',
    insufficientFunds: 'The transactions are not payed yet',
    waiting: 'Waiting for the user payment',
    cancelShop: 'Cancel buy',
    print: 'Print receipt',
    shortcuts: 'Shortcuts amount',
    payCard: 'Card Payment',
    payCardSingle: 'Single Card Payment',
    payCash: 'Cash Payment',
    payCHTitle: "Pay with player's account",

    payCH: "Player's account",
    payoutTicket: 'Open tickets',
    registerNewAnonUser: 'New Anon User',
    registerNewHybridUser: 'New Daily User',
    registerNewMember: 'New Member',
    editHybridUser: 'Edit Daily User',
    editMember: 'Edit Member',
    optional: '(optional)',
    userTooSmall: 'Search should have at least 7 digits',
    userNotFound: "The user doesn't exist, do you want to create a new one?",
    usersFound: 'More than one user has been found',
    selectUser: 'Use this user',

    registerNewUserConfirm: 'Register new user',
    editUserConfirm: 'Edit new user',
    anonToUser:
      'Do you want to edit the current daily user or register it as a new member?',
    hybridToUser:
      'Do you want to edit the current daily user or register it as a new member?',
    phonenumber: 'Phonenumber',
    newUser: 'New daily user',
    newMember: 'New member',
    newMemberText:
      'The costumer must confirm their identity. Ask the costumer which method they want to use to verify their identity.',
    newMemberButtonID: 'ID Card, Driver License or Passport',
    newMemberButtonBankID: 'BankID',
    updateHybridUser:
      'This is a daily user, you can edit it or register it as a new member:',
    lastUsers: 'Last users',
    sellMessage:
      'Remember to receive cash from the customer before pressing TOTAL',
    sellMessageCash1: 'Remember to take ',
    sellMessageCash2: ' KR from the costumer. ',
    sellMessageReturnCash1: 'Remember to give back ',
    sellMessageReturnCash2: ' KR to the costumer. ',
    name: 'Name',
    surname: 'Surname',
    nameAndSurname: 'Name and surname',
    unblock: 'Send confirmation to the user to unlock the tickets',
    send: 'Send',
    unregistered: 'Unregistered',
    registerAnonPlayer: 'Register the daily user',
    paymentAnon:
      'Daily players only can pay by card, do you want to register this daily user?',
    registerAnonPlayerConfirm: 'Register',
    transactionDone: 'Transaction complete',
    backToPayment: 'Return to the payment',
    transactionFail: 'Transaction failed or declined',
    smsTitle: 'Waiting for pincode',
    smsText1:
      "An SMS with a pincode has been sent to customer's mobile number to confirm his identity. ",
    smsText2: 'The costumer has to confirm the pincode in the screen.',
    pincodeTitle: "Waiting for user's pin",
    pincodeText1:
      'The user should introduce the 4 digit pincode associated with his account: ',
    userConfirm: 'Successfully registered user',
    selected: 'Selected',
    internetAccess: 'Forbid internet access',

    chooseOne: 'Choose one...',
    passport: 'Passport',
    driverLicense: 'Driver License',
    electronicID: 'Electronic(BankID)',
    bankCard: 'Bank card',
    nationalID: 'National ID',
    other: 'Other',
    terms: 'Terms of Use and Privacy Policy',
    communication: "User's communication preferences",
    pincode: 'Generate a pincode',
    newPincode: 'Ny pinkode',
    pincodeLabel: 'Pincode',
    cardID: 'Card ID',
    nfcCardID: 'NFC Card ID',
    ssnid: 'SSN ID',
    username: 'Username',
    firstname: 'First Name',
    lastname: 'Last Name',
    address: 'Address',
    verificationMethod: 'Verification Method',
    country: 'Country',
    postalCode: 'Postal Code',
    region: 'Region',
    email: 'Email',
    birthdate: 'Birthdate',
    verificationID: 'Verification ID',
    cashoutPending: 'The user has a pending cash out :',
    cashoutNow: 'Cash out now',
    signatureTitle: 'Signature required',
    signatureText:
      "The current transaction needs to be signed to be approved. Waiting for the user's signature.",
    signatureTitleID: 'ID confirmation required',
    signatureTextID:
      "An ID confirmation is required to continue. Take a picture of the user's ID to verify the user's identity.",
    signatureConfirmSMS: "A pincode will be sent to the user's phone",
    signatureUserNeedsMobile:
      'This user has not a phone number registered. A phone number is mandatory to proceed. ',
    signatureConfirm: 'Transactions approved',
    openSessionTitle: 'Open session found',
    openSessionText1: 'This ticket has an open session in the terminal ',
    openSessionText2: ' with a total amount of ',
    openSessionTextClose: 'Do you want to close the session?',
    openSessionTextUnclosable:
      'This session need to be closed in the terminal first',
    chooseProduct: 'Choose a product',

    ticket7Pincode: 'Ticket Pincode',
    currentSaldo: 'Current Saldo',
    editHybridToFull: 'Convert into member',
    userLimits: 'User Limits',
    noLimits: 'No limits',
    cashIn: 'Cash In',
    cashOut: 'Cash Out',
    limitsUpdated: 'Limits updated for this user',
    cardToCash: 'Cash withdrawals',
    limitDaily: 'Daily limit',
    limitMonthly: 'Monthly limit',
    isLimitSurpassed: 'Limit surpassed',
    isLimitSurpassedDaily:
      'This user has surpassed the limit and is not allowed to play more today',
    isLimitSurpassedMonth:
      'This user has surpassed the limit and is not allowed to play more this month',
    isVerificationNeeded: 'Verification required',
    isVerificationNeededText:
      'This user needs to be verified to continue, edit the user to verify it.',
    isLimitSurpassedTransaction1:
      "The current transaction is not allowed because it surpasses the user's limit.",
    isLimitSurpassedTransaction2: 'The maximum amount allowed is: ',
    ticketWithDifferentParentTitle: "This ticket doesn't belong to this user",
    ticketWithDifferentParentText:
      'This ticket belongs to another user, do you want to make the payout for the current user?',
    photoVerificationTitle: "This user doesn't have a verified photo",
    photoVerificationText:
      "Do you want to verify the user's photo? Take a picture of the user or user's ID to verify the user's identity.",

    photoUploadTitle: 'Take the picture',
    photoUploadText:
      'Use one of the cameras to take a picture of the user face or any other valid document, like a passport or ID card.',
    photoUploadTextSignature:
      'Use one of the cameras to take a picture of any user valid document, like a passport or ID card.',
    photoUploadSuccess: 'The picture has been uploaded successfully',
    userVerificationImage: 'User verification image',
    userVerificationImageNew: 'Take a new image',
    saldo: 'Balance',
    status: 'Status',
    open: 'Open',
    closed: 'Closed',
    inGame: 'In game',
    ticketClosedOK: 'This ticket is already closed',
    editUserBlock: 'Finish the current operation to edit the user',
    editUserConfirmButton: 'Edit',
    bigNumberConfirmTitle: 'Transaction over 10.000',
    bigNumberConfirmText1:
      'The amount of this transaction is higher than the usual, do you want to continue?',
    bigNumberConfirmText2: 'Total amount: ',
    deleteUser: 'Delete user',
    banUser: 'Disable user',
    banUserTitle: 'Disable user',
    banUserText:
      'Are you sure you want to disable this user? Select the reason and for how long you want to disable the user.',
    banUserReason: 'Reason',
    banUserReasonPlaceholder: 'Select a reason',
    banUserDuration: 'Duration',
    banUserDurationPlaceholder: 'Select a duration',
    deleteUserTitle:
      'You are going to delete this user, are you sure you want to continue?',
    deleteUserConfirm: 'The user has been deleted',
    closeSellerOrPayment:
      'Finish the current operation or come back to the main screen to scan a new ticket',
    invalidTicket: 'Invalid ticket',
    userSignatureCheckTitle: 'Identification check required',
    userSignatureCheckText:
      'Ask the customer for a valid document to verify the signature. Remember that you will need to take a picture of the document if you approve the signature.',
    newSpecialGameLuckyDay: "New 'Lucky Day' roulette",
    newSpecialGameLuckyDayText1:
      "A new 'Lucky Day' roulette has been played in this hall.",
    newSpecialGameLuckyDayText2:
      'The user need to match the number of the roulette with the current day of the month to win the prize.',
    newSpecialGameLuckyDayText3: 'Today is: ',
    newSpecialGameLuckyDayText4: 'Has the user got the right number?',
    restart: 'Restart',
    reason: 'Reason: ',
    switchCamera: 'Switch camera',
    specialGameMessageNoPrizeConfirm:
      "The user hasn't won the special prize, and the game will pay the standard bingo prize and the bildes and rammes if they exist. Do you want to continue?",
    termsSignature:
      "In order to proceed and store the user's signature, the user must agree with the Privacy Policy and accept that the id will remain store in our systems up to two years.",
    signatureStored: 'Signature stored',
    signatureStoredText:
      'The user already has a stored valid identification, check the identity of the user and confirm to continue the transaction.',
    signatureStoredDate: 'Identification stored on',
    postponeIdentification: 'Postpone identification',
    postponeIdentificationText:
      'Postpone the identification for this transaction and continue with the payment (the user will need to verify the identification within the next 2 weeks.)',
    waitingForScan: 'Waiting for a card scan',
    waitingForScanText:
      'Scan one of the CH cards to attach to this user automatically',
    pep: 'P.E.P. or close relative is P.E.P.',
    waitingForUser: 'Waiting for user',
    waitingForRiskyInfo: 'The user must provide additional information',
    waitingForUserTerms: 'The user must accept the terms of use',
    waitingForUserPEP:
      'The user must confirm if he is a P.E.P. and fulfill the form',
    waitingForUserCommunication:
      'The user must select communication channels we can use',
    waitingForUserPincodeTitle: "Waiting for user's PIN code",
    waitingForUserPincodeText:
      'The user should enter the 4-digit PIN code associated with their account',
    newMemberBankID:
      'The costumer will receive a SMS with a link to register with their BankID.',
    smsSent:
      "A SMS with a link to register and identify the user has been sent to the user's phone number",
    importantTitle: 'Important announcement',
    importantSubtitle:
      "Starting from 1st of January 2025, all daily users must be registered with a valid ID as members. If the user can't register with a valid ID right now, continue by canceling this announcement.",
    iKnowTheUser: 'I know the user',
    identityConfirm: "The user's identity has been confirmed",
    countryOfResidence: 'Country of Residence',
    confirmPicture: 'Confirm picture',
    continueWithoutRegister: 'Continue without register',
    registerAsMember: 'Register as member',

    waitingForApproval: 'Waiting for approval',
    waitingForApprovalText:
      'This user needs a manual approval to continue, please wait for the moment or contact support.',
    suspectedFraud: 'Suspected fraud',
    maxFailedLoginAttempts: 'Max failed login attempts',
    incorrectPersonalInformation: 'Incorrect personal information',
    otherBanned: 'Other',
    underaged: 'Underaged',
    hrl: 'HRL',
    pepBanned: 'PEP',
    badBehaviour: 'Bad behaviour',
    selfExclusionFor1Month: 'Self exclusion for 1 month',
    selfExclusionFor3Months: 'Self exclusion for 3 months',
    selfExclusionFor6Months: 'Self exclusion for 6 months',
    selfExclusionFor1Year: 'Self exclusion for 1 year',
    deletedAccount: 'Deleted account',
    userIsNotAllowed: 'User is not allowed to play',
    time1week: '1 week',
    time1month: '1 month',
    time3months: '3 months',
    time6months: '6 months',
    time1year: '1 year',
    riskyCountryTitle: 'You are required to provide additional information',
    riskyCountry:
      'You are from a country that required a manual approval and an extra information to continue.',
    residence: 'Residence',
    confirmLogin: "Confirm customer's identity",
    confirmLoginText:
      "Use the profile picture to confirm the customer's identity, take a new picture, ask the customer to enter the withdrawal PIN code, or confirm that you know the customer to continue.",
    confirmLoginNewMember: 'New picture',
    confirmLoginPincode: 'PIN code',
    confirmLoginIKnowTheUser: 'Know the customer or ID OK',
    sendPincodeBySMS: 'Send pincode SMS',
    sendPincodeBySMSText:
      "The user's pincode has been sent to the user's phone number",
    createNewUser: 'New member',
    sendOneTimeCodeBySMS: 'One time code by SMS',
    newConfirmTitle: 'The user has confirmed their identity',
    newConfirmText:
      'The user has used their withdrawal PIN to confirm their identity, press confirm to continue',
  },
  pep: {
    pep: 'PEP',
    customerIsPep: 'The customer is a PEP',
    customerIsNotPep: 'The customer is not a PEP',
    customerIsPepRelative: 'The customer is a close relative of a PEP',
    pepQuestion: 'Are you a Politically Exposed Person (PEP)?',
    pepDescription:
      'You are a PEP if you (or someone in your immediate family) currently hold or have previously held a high-ranking position or office in Norway or abroad.',
    pepUncertain: 'Not sure which positions apply?',
    checkList: 'Check the list here',
    positions: [
      'Head of state, head of government, minister, or deputy minister',
      'Member of a national assembly',
      'Member of a governing body of a political party',
      'Member of a supreme court or similar high-level judicial body whose decisions are rarely subject to appeal',
      'Member of the board of a national audit office, audit court, or central bank',
      "Ambassador, chargé d'affaires, or senior military officer",
      'Member of an administrative, executive, or supervisory body in a state-owned enterprise',
      'Director, board member, or other person in top management of an international organization',
    ],
    pepYes: 'I am a PEP',
    pepNo: 'I am not a PEP',
    tellWhoIsPep: 'Tell us who is a PEP',
    formPEP: 'PEP form',
    relationshipToPep: 'Your relationship to the PEP',
    name: 'Name',
    birthDate: 'Date of birth',
    gender: 'Gender',
    fundsQuestion: 'What funds will you use to play?',
    funds: [
      'Salary',
      'Sale/rental of property',
      'Sale of shares or funds',
      'Benefits, pension, student loans, or other support channels',
      'Gifts, inheritance, or similar',
    ],
    consent: 'Consent',
    consentStatements: [
      'I confirm that the bank accounts linked to my gaming account for deposits/withdrawals are in my name',
      'I confirm that the funds I will use for gaming, including cash, are legally acquired and taxed',
      'I confirm that I will personally report the balance of the gaming account to the authorities when submitting my tax return',
    ],
    male: 'Male',
    female: 'Female',
    other: 'Other',
    required:
      'You must accept all the statements to continue and choose both the relationship and the funds',
    riskyRequired:
      'You must accept all the statements to continue and choose where your funds come from',
    userNotReady: "The user hasn't finished the identification process",
  },
  communication: {
    title: 'Where can we reach you?',
    subtitle:
      'Choose where you want to receive useful information about winnings, horse betting, and other personalized content.',
    option1: 'SMS',
    option2: 'Email',
    option3: 'Receive tailored offers and news on social media',
    option4: 'Select all of the above',
  },
  terms: {
    title: 'Terms of Use and Privacy Policy',
    termsText1: "I have read and accept Bingo 1's ",
    termsText2: ' terms of use and privacy policy',
    accountText: 'The gaming account shall only be used by me *',
    required: 'You must accept the terms of use and privacy policy to continue',
  },
  error: {
    empty: 'Fields shall not be empty',
    login: 'Username or password incorrect',
    blockProvider:
      'It is not possible to sell tickets from two different databingo providers for the same user or more than one ticket',

    userNotLogin: 'You must login an user first',
    userNotMember: 'This user is not a ClubHouse member',
    error: 'Something went wrong, try again in a moment or contact support.',
    errorTooSmall: 'The search by pin must have at least 7 digits',
    errorPhonenumber: 'The search by phone number must be 7 digits',
    errorPhonenumberInput:
      'The phonenumber is not valid. Use a valid norwegian 8 digits number',
    errorUsernameInput: "Username can't be empty",
    errorCardIDEmpty: "Card ID can't be empty",
    errorCardIDFormat: 'Card ID format is incorrect',
    errorUsernameEmpty: "Username can't be empty",
    errorUsernameTooShort: 'Username is too short, minumum 5 characters',
    errorUsernameTooLong: 'Username is too long, maximum 12 characters',
    errorFirstnameEmpty: "First name can't be empty",
    errorLastnameEmpty: "Last name can't be empty",
    errorAddress1Empty: "Address can't be empty",
    errorPostalCodeEmpty: "Postal code can't be empty",
    errorRegionEmpty: "Region can't be empty",
    errorEmailEmpty: "Email can't be empty",
    errorBirthdateEmpty: "Birthdate can't be empty",
    errorBirthdateFormat: 'Birthdate format is incorrect',
    errorVerificationIDEmpty: "Verification ID can't be empty",
    errorVerificationMethodEmpty: "Verification Method can't be empty",
    errorPhonenumberExists: 'Phonenumber already registered',
    errorUsernarmeExists: 'Username already registered',
    errorEmailExists: 'Email already registered',
    errorCardIDExists: 'Card ID already registered',
    errorNfcCardIDExists: 'NFC Card ID already registered',
    errorSSNIDExists: 'SSN ID already registered',
    errorUserFailCode: 'The code introduce by the user is not correct',
    errorRefreshTransactions:
      "You can't restart the cashier with pending transactions",
    errorNavigateTransactions:
      "You can't change the screen with an user logged in or with pending transactions",
    errorCode: 'Fail code',
    errorLimitSize: "The daily limit can't be lower than the monthly limit",
    errorEmptyLimits: "The limits can't be empty",
    errorImageRegister:
      "You need to verify the user's image before finish the registration",
    errorSSNIDInvalid: 'The SSN ID is not valid',
    errorSSNIDAge: 'The user is under 18 years old',
    errorCancelOpenSell:
      'For cancel the selected transaction, you need to finish this sell or come back to the main screen',
    errorPEP:
      'User must confirm if he is a P.E.P. or close relative of a P.E.P. to continue',
    errorTermsEmpty:
      'The user must accept the terms of use and privacy policy to continue',
    errorCommunicationEmpty:
      'The user must select the communication channels to continue',
    errorPincodeEmpty: 'The pincode can not be empty',
    errorRiskyEmpty: 'The user must provide additional information to continue',
  },
  sisteSalgs: {
    date: 'Date',
    bingoName: 'Hall',
    operator: 'Operator',
    payCard: 'Payed by Card',
    payCash: 'Payed by Cash',
    total: 'Total',
    change: 'Change',
    ticket7Pincode: 'Ticket Pincode',
    userID: 'User ID',
    ticketID: 'Ticket ID',
    username: 'Username',
    printed: 'Printed',
    transactions: 'See transactions',
    description: 'Description',
    amount: 'Amount',
    printAgain: 'Print',
    backToSells: 'Back to sells',
  },
  sisteTrans: {
    date: 'Date',
    bingoName: 'Hall',
    operator: 'Operator',
    payCard: 'Payed by Card',
    payCash: 'Payed by Cash',
    payAccount: 'Payed by Account',
    total: 'Total',
    change: 'Change',
    ticket7Pincode: 'Ticket Pincode',
    ticketID: 'Ticket ID',
    username: 'Username',
    printed: 'Printed',
    transactions: 'See transactions',
    description: 'Description',
    amount: 'Amount',
    printAgain: 'Print',
  },
  apneBilleter: {
    date: 'Date',
    bingoName: 'Hall',
    operator: 'Operatør',
    ticket7Pincode: 'Ticket Pincode',
    user7Pincode: 'User',
    ticketID: 'Ticket ID',
    description: 'Description',
    amount: 'Amount',
    printAgain: 'Print',
    seeTransactions: 'See transactions',
    username: 'Username',
  },
  ticketHistoric: {
    ticketToSearch: '7 digit ticket to search',
    date: 'Date',
    action: 'action',
    openSession: 'Opened session',
    closeSession: 'Closed session',
    payment: 'Buy cards',
    win: 'Win prize',
    cancel: 'Cancel cards',
    deposit: 'Deposit',
    depositFromPreviousSession: 'Deposit from previous session',
    saldoInit: 'Initial balance',
    saldoEnd: 'Final balance',
    bingoName: 'Hall',
    gameID: 'Game ID',
    valueChange: 'Value Change',
  },
  menu: {
    search: 'Search',
  },
  report: {
    startDay: "There's not open shift. Start a new one now.",
    startDayButton: 'Start shift.',
    confirm: 'Confirm',
    edit: ' Edit',
    cashin: 'Cashin',
    cashout: 'Cashout',
    neto: 'Neto',
    next: 'Next',
    back: 'Back',
    picture: ' Picture',
    errorTicket: 'You need to upload the tickets first.',
    capturePicture: 'Capture picture',
    resetCamera: 'Reset camera',
    date: 'Date',
    employee: 'Employee',
    miniSafeYesterday: 'Minisafe Yesterday',
    cashierYesterday: 'Cashier balance yesterday',
    cashierInOpening: 'Cashier balance at opening',
    difference: 'Difference',
    cashierTotal: 'Cashier total',
    cashierRefill: 'Cashier refill',
    sendReport: 'Send report',
    theoric: 'Theoric',
    minisafe: 'Minisafe',
    cashier: 'Cashier',
    deviated: 'Deviated',
    acceptDeviated: 'Accept deviated',
    closeReport: 'Daily report ',
  },
  endShift: {
    title: 'Select one of the options:',
    subtitle:
      'You will need to do both, the card terminal report and the daily cashier report',
    cardReport: 'Card terminal report',
    cashierReport: 'Clubhouse report',
    winGamesReport: 'Win Games report',
    completed: 'Completed',
    notCompleted: 'Not completed',
    confirmCardReport: 'Do you want to confirm the card terminal report?',
    confirmWinGamesReport:
      'Do you want to confirm the win games terminal report?',
    cashin: 'Cash in',
    cashout: 'Cash out',
    neto: 'Neto',
    cardPayment: 'Betalinger utført med kort',
    cardPayout: 'Enkeltbetaling med kort',
    cardTotal: 'Totalt med kort',
    waiting: 'Processing the report, wait for a moment.',
  },
  socketStatus: {
    disconnectTitle: 'Connection lost',
    disconnectText:
      'The connection with the server has been lost. Trying to reconnect, keep waiting or call support.',
    reconnectTitle: 'Reconnected',
    reconnectTextNoTransactions:
      'The connection with the server has been reestablished. We recommend you to refresh the cashier.',
    reconnectTextTransactions:
      'The connection with the server has been reestablished. We recommend you to refresh the cashier after finish the current transactions.',
    refresh: 'Refresh now',
    notRefresh: "Don't refresh",
  },
};
