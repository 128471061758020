import React, { useEffect, useContext } from 'react';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  NextPlan as NextPlanIcon,
} from '@mui/icons-material/';

function ModalUserNotFound() {
  const { t } = useTranslation();

  const {
    updateFocusOnMainScreen,
    updateShowModalUserNotFound,
    updateIsHybridUserEditing,
    isHybridUserRegistration,
    updateIsFullUserRegistration,
    updateIsHybridUserRegistration,
  } = useContext(AppContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  useEffect(() => {}, [socket]);

  //* CANCEL BUTTON (close the modal and go back to main screen)
  const onClose = () => {
    updateFocusOnMainScreen(true);
    updateShowModalUserNotFound(false);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            <h1>{t('sales.userNotFound')}</h1>

            <div className="react-confirm-alert-button-group">
              {/*
              <button
                className="btn-custom-yes"
                onClick={() => {
                  onClose();
                  updateIsHybridUserEditing(true);
                }}
              >
                {t("sales.registerNewAnonUser")}
              </button>
              */}

              {/*
              <button
                className="btn-custom-yes"
                onClick={() => {
                  updateFocusOnMainScreen(false);
                  onClose();
                  //isHybridUserRegistration(true);
                  updateIsHybridUserRegistration(true);
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                {t('sales.registerNewHybridUser')}
              </button>
              */}

              <button
                className="btn-custom-yes"
                onClick={() => {
                  updateFocusOnMainScreen(false);
                  onClose();
                  //updateIsHybridUserEditing(true);
                  updateIsFullUserRegistration(true);
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                {t('sales.createNewUser')}
              </button>

              <button
                className="btn-custom-no"
                onClick={() => {
                  onClose();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CancelIcon style={{ fontSize: '1.2em' }} />

                {t('sales.cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalUserNotFound;
