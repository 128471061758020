import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Grid,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
  Paper,
} from '@mui/material';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import BlurTable from '../../../assets/img/blurTable.jpg';

import { SocketContext } from '../../../context/SocketContext';
import toast from 'react-hot-toast';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../assets/css/buttons.css';

function SellScreenListWG({ updateAmountShortcut, isTab, isRefill }) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const loadingTable = Array.from({ length: 12 });

  const {
    WGBingoID,
    userSiblings,
    userChildren,
    session,
    operator,
    hall,
    updateUserLogin,
    currentUser,
    ticketsCH,
    userInfo,
    parentInfo,
    ticketsWG,
    updateTicketsWG,
    currentTicketWG,
    updateCurrentTicketWG,
    updateTicketsOKWithNewSaldo,
    isWGEnabled,
    ticketsTempWG,
  } = useContext(AppContext);

  const WGBingoIDRef = useRef(WGBingoID);
  useEffect(() => {
    WGBingoIDRef.current = WGBingoID;
  }, [WGBingoID]);

  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if (!isWGEnabled) {
      return;
    }
    if (!WGBingoIDRef.current || WGBingoIDRef.current === 0) {
      return;
    }
    socket.emit('ticketsWG', {
      operator: operator,
      bingoName: hall,
      userID: parentInfo ? parentInfo.id : userInfo.id,
      userInfo: parentInfo ? parentInfo : userInfo,
      session: session,
      action: 'ticketsWG',
      bingoID: WGBingoIDRef.current,
    });
  }, [WGBingoID]);

  useEffect(() => {
    if (!isWGEnabled) {
      return;
    }
    if (!ticketsWG || ticketsWG.length === 0) {
      return;
    }
    for (let i = 0; i < ticketsWG.length; i++) {
      if (
        currentTicketWG.wgID === ticketsWG[i].wgID &&
        ticketsWG[i].currentBalance &&
        !isRefill
      ) {
        updateCurrentTicketWG(ticketsWG[i]);
        updateAmountShortcut(ticketsWG[i].currentBalance);
      }
    }
  }, [ticketsWG]);

  useEffect(() => {
    console.log('currentTicketWG', currentTicketWG);
  }, [currentTicketWG]);

  useEffect(() => {
    console.log('ticketsTempg', ticketsTempWG);
  }, [ticketsTempWG]);

  useEffect(() => {
    const handleTicketsWG = (data) => {
      console.log('ticketsWG socketOn', data);
      if (data.error) {
        toast.error(t('error.error'));
        return;
      }

      setLoading(false);
      updateTicketsWG(data.ticketsWG);
    };

    socket.on('ticketsWG', handleTicketsWG);

    return () => {
      socket.off('ticketsWG', handleTicketsWG);
    };
  }, [socket]);

  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  const activateTicket = (ticket) => {
    if (!ticket.wgInfo.amount) {
      return;
    }
    if (!isRefill) {
      updateAmountShortcut(ticket.wgInfo.amount);
    }
    updateCurrentTicketWG(ticket);
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: '10px',
        height: '555px',
        width: '100%',
        marginTop: '0',
        marginLeft: 0,
        justifyContent: 'center',
        alignContent: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {!isTab && (
        <h2 style={{ textAlign: 'left', width: '100%' }}>
          {t('sales.payoutTicket')}
        </h2>
      )}

      <Grid
        container
        flexDirection="row"
        sx={{
          height: '100%',
          width: '100%',
          marginTop: 0,
          marginLeft: 0,
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            border: '2px solid black',
            width: '100%',
            maxWidth: '100% !important',
            height: '535px',
            backgroundColor: '#232a45',
          }}
        >
          {loading ? (
            <TableContainer
              component={Paper}
              sx={{
                height: '535px',
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              <Table
                sx={{
                  color: 'white',
                  background: '#1f2c67',
                }}
                stickyHeader
                aria-label="simple table"
              >
                <TableHead
                  sx={{
                    background: '#1f2c67',
                  }}
                >
                  <TableRow>
                    <TableCell align="center">
                      {t('apneBilleter.ticketID')}
                    </TableCell>
                    <TableCell align="center">{t('sales.saldo')}</TableCell>

                    <TableCell align="center"> {t('sales.status')}</TableCell>
                    <TableCell align="center"> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingTable.map((index) => (
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:nth-of-type(odd)': {
                          backgroundColor: '#232a45',
                        },
                        '&:nth-of-type(even)': {
                          backgroundColor: '#1a2036',
                        },
                      }}
                      key={index}
                    >
                      <TableCell component="th" scope="row" align="center">
                        <Skeleton />
                      </TableCell>

                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>

                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>

                      <TableCell align="center">
                        {/* Button */}
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer
              component={Paper}
              sx={{
                height: '535px',
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              <Table
                sx={{
                  color: 'white',
                  background: '#1f2c67',
                }}
                stickyHeader
                aria-label="simple table"
              >
                <TableHead
                  sx={{
                    background: '#1f2c67',
                  }}
                >
                  <TableRow>
                    <TableCell align="center">
                      {t('apneBilleter.ticketID')}
                    </TableCell>
                    <TableCell align="center">{t('sales.saldo')}</TableCell>

                    <TableCell align="center">{t('sales.status')} </TableCell>
                    <TableCell align="center"> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ticketsWG && ticketsWG.length > 0 && (
                    <>
                      {ticketsWG.map((ticket, index) =>
                        ticket.wgInfo.isCashedOut ||
                        ticket.isCashedOut ||
                        ticketsTempWG.some(
                          (ticketTemp) =>
                            parseInt(ticketTemp.id) === ticket.wgID
                        ) ? null : (
                          <TableRow
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              '&:nth-of-type(odd)': {
                                backgroundColor: '#232a45',
                              },
                              '&:nth-of-type(even)': {
                                backgroundColor: '#1a2036',
                              },
                            }}
                            key={index}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              sx={{
                                color:
                                  currentTicketWG.wgID === ticket.wgID
                                    ? '#18ff18'
                                    : 'inherit',
                              }}
                            >
                              {ticket.wgID}
                            </TableCell>

                            <TableCell
                              align="center"
                              sx={{
                                color:
                                  currentTicketWG.wgID === ticket.wgID
                                    ? '#18ff18'
                                    : 'inherit',
                              }}
                            >
                              {ticket.currentBalance !== undefined &&
                              ticket.currentBalance !== null
                                ? ticket.currentBalance
                                : '-'}
                            </TableCell>

                            {/*Status*/}
                            <TableCell
                              align="center"
                              sx={{
                                color:
                                  currentTicketWG.wgID === ticket.wgID
                                    ? '#18ff18'
                                    : 'inherit',
                              }}
                            >
                              {ticket.wgInfo.isInUse || ticket.isInUse
                                ? t('sales.inGame')
                                : ticket.wgInfo.isCashedOut ||
                                    ticket.isCashedOut
                                  ? t('sales.closed')
                                  : t('sales.open')}
                            </TableCell>

                            <TableCell align="center">
                              {ticket.currentBalance && (
                                <>
                                  {currentTicketWG.wgID !== ticket.wgID ? (
                                    <>
                                      {!isRefill && !ticket.isInUse && (
                                        <Button
                                          size="small"
                                          variant="outlined"
                                          color="primary"
                                          onClick={() => {
                                            if (
                                              ticket.currentBalance === 0 &&
                                              !isRefill
                                            ) {
                                              return;
                                            }
                                            activateTicket(ticket);
                                          }}
                                        >
                                          {!isRefill
                                            ? t('sales.payout')
                                            : t('sales.refill')}
                                        </Button>
                                      )}

                                      <>
                                        {isRefill && (
                                          <Button
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                              if (
                                                ticket.currentBalance === 0 &&
                                                !isRefill
                                              ) {
                                                return;
                                              }
                                              activateTicket(ticket);
                                            }}
                                          >
                                            {!isRefill
                                              ? t('sales.payout')
                                              : t('sales.refill')}
                                          </Button>
                                        )}
                                      </>
                                    </>
                                  ) : (
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      color="success"
                                      onClick={() => {
                                        if (
                                          ticket.newSaldo === 0 &&
                                          !isRefill
                                        ) {
                                          return;
                                        }
                                        activateTicket(ticket);
                                      }}
                                    >
                                      {t('sales.selected')}
                                    </Button>
                                  )}
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SellScreenListWG;
