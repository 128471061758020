import React, { useEffect, useContext, useState, useRef } from 'react';
import { Grid } from '@mui/material';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import toast from 'react-hot-toast';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  PersonRounded,
  Pin,
  Sms,
} from '@mui/icons-material/';

function ModalPictureVerificationWarning() {
  const { t } = useTranslation();

  const {
    operator,
    updateIsLoadingScreen,
    session,
    updateIsLoginFromNfc,
    isLoginFromNfc,
    updateIsNeedConfirmation,
    updateShowModalConfirmIdentity,
    userImageInfo,
    updateIsFullUserRegistration,
    updateIsFullUserEditing,
    updateFocusOnMainScreen,
    logoutUser,
    updateShowModalPictureVerification,
    updateIsLimitSurpassed,
    updateIsDailyLimitSurpassed,
    updateIsMonthlyLimitSurpassed,
    updateShowUserInfo,
    updatePrint,
    resetTransactions,
    updateReceiptToPrint,
    updateIsCard,
    updateIsCash,
    updatePayCard,
    updatePayCash,
    updatePaySpillerkonto,
    updateChange,
    updatePaymentType,
    updateIsPaymentOpen,
    updateIsSellerOpen,
    updateCurrentUser,
    updateCurrentUserID,
    updateUserLogin,
    updateUserInfo,
    updateIsFastUserLogin,
    updateIsAnonUser,
    updateIsHybridUser,
    updateIsFullUser,
    updateUserParent,
    updateUserChildren,
    updateUserSiblings,
    resetTicketsCH,
    updateTotal,
    updateIsVerificationNeeded,
    hall,
    parentInfo,
    userInfo,
    updateIsIdentityConfirmed,
  } = useContext(AppContext);

  const [isWaiting, setIsWaiting] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [isFirstCheck, setIsFirstCheck] = useState(true);
  const isFirstCheckRef = useRef(isFirstCheck);
  useEffect(() => {
    isFirstCheckRef.current = isFirstCheck;
  }, [isFirstCheck]);

  const [smsSent, setSmsSent] = useState(false);
  const [smsHybridRegistration, setSmsHybridRegistration] = useState('');
  const smsHybridRegistrationRef = useRef(smsHybridRegistration);
  useEffect(() => {
    smsHybridRegistrationRef.current = smsHybridRegistration;
  }, [smsHybridRegistration]);

  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  const operatorRef = useRef(operator);
  useEffect(() => {
    operatorRef.current = operator;
  }, [operator]);

  const hallRef = useRef(hall);
  useEffect(() => {
    hallRef.current = hall;
  }, [hall]);

  const userInfoRef = useRef(userInfo);
  useEffect(() => {
    userInfoRef.current = userInfo;
    if (userInfo && !parentInfo && userInfo.username.startsWith('hybrid')) {
      updateShowModalConfirmIdentity(false);
      updateIsLoginFromNfc(false);
    }
  }, [userInfo]);

  const parentInfoRef = useRef(parentInfo);
  useEffect(() => {
    parentInfoRef.current = parentInfo;
    if (parentInfo && parentInfo.username.startsWith('hybrid')) {
      updateShowModalConfirmIdentity(false);
      updateIsLoginFromNfc(false);
    }
  }, [parentInfo]);

  useEffect(() => {
    console.log('userImageInfo', userImageInfo);
  }, [userImageInfo]);

  const isLoginFromNfcRef = useRef(isLoginFromNfc);
  useEffect(() => {
    console.log('isLoginFromNfc', isLoginFromNfc);
    isLoginFromNfcRef.current = isLoginFromNfc;
  }, [isLoginFromNfc]);

  const [isOneTimeCode, setIsOneTimeCode] = useState(false);
  const isOneTimeCodeRef = useRef(isOneTimeCode);
  useEffect(() => {
    isOneTimeCodeRef.current = isOneTimeCode;
  }, [isOneTimeCode]);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    socket.emit('checkUserPincodeLogin', {
      bingoName: hallRef.current,
      userID: parentInfoRef.current
        ? parentInfoRef.current.id
        : userInfoRef.current.id,
      user7Pincode: parentInfoRef.current
        ? parentInfoRef.current['']
        : userInfoRef.current[''],
    });
    updateIsLoadingScreen(true);

    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  var isBusy = false;

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);
  useEffect(() => {
    const handleSendPincodeTerminal = (data) => {
      if (!isOneTimeCodeRef.current) {
        var currentPincode = 0;
        if (parentInfo) {
          currentPincode = parentInfo.PIN2;
        } else {
          currentPincode = userInfo.PIN2;
        }

        if (!isBusy && currentPincode !== data.pincode) {
          isBusy = true;

          toast.error(t('error.errorCode'));
          socket.emit('activateSMS', {
            bingoName: hallRef.current,
          });

          setTimeout(() => {
            isBusy = false;
          }, 3000);
          return;
        }

        if (!isBusy && currentPincode === data.pincode) {
          setIsWaiting(false);
          setIsSuccess(true);

          socket.emit('deactivateSMS', {
            bingoName: hallRef.current,
          });

          socket.emit('registerUserPincodeLogin', {
            bingoName: hallRef.current,
            userID: parentInfoRef.current
              ? parentInfoRef.current.id
              : userInfoRef.current.id,
            user7Pincode: parentInfoRef.current
              ? parentInfoRef.current['7 digit PIN']
              : userInfoRef.current['7 digit PIN'],
            operator: operatorRef.current,
          });
        }
      } else {
        if (smsHybridRegistrationRef.current === '') {
          return;
        }

        if (parseInt(data.pincode) !== smsHybridRegistrationRef.current) {
          toast.error(t('error.errorUserFailCode'));
          socket.emit('activateSMS', {
            bingoName: hallRef.current,
          });
          return;
        }
        socket.emit('deactivateSMS', {
          bingoName: hallRef.current,
        });
        setIsWaiting(false);
        setIsSuccess(true);
      }
    };

    const handleSendPincodeSMS = (data) => {
      if (data) {
        toast.success(t('sales.sendPincodeBySMSText'));
      } else {
        toast.error(t('error.error'));
        setSmsSent(false);
      }
    };

    const handleSmsOneTimeCodeConfirmIdentity = (data) => {
      setSmsHybridRegistration(data);
    };

    const handleCheckUserPincodeLogin = (data) => {
      updateIsLoadingScreen(false);

      if (data && !isFirstCheckRef.current) {
        setIsWaiting(false);
        setIsSuccess(true);
        setIsFirstCheck(false);

        socket.emit('deactivateSMS', {
          bingoName: hallRef.current,
        });
        return;
      }

      if (data && isFirstCheckRef.current && isLoginFromNfcRef.current) {
        setIsWaiting(false);
        setIsSuccess(true);
        setIsFirstCheck(false);

        socket.emit('deactivateSMS', {
          bingoName: hallRef.current,
        });
        return;
      }

      if (isFirstCheckRef.current) {
        setIsFirstCheck(false);
        return;
      }

      /* Pre 24h validation*/
      socket.emit('activateSMS', {
        bingoName: hallRef.current,
      });
      setIsWaiting(true);
    };

    const handleRegisterUserPincodeLogin = (data) => {
      return;
    };

    socket.on('checkUserPincodeLogin', handleCheckUserPincodeLogin);
    socket.on('registerUserPincodeLogin', handleRegisterUserPincodeLogin);
    socket.on('sendPincodeTerminal', handleSendPincodeTerminal);
    socket.on(
      'smsOneTimeCodeConfirmIdentity',
      handleSmsOneTimeCodeConfirmIdentity
    );
    socket.on('sendPincodeSMS', handleSendPincodeSMS);
    return () => {
      socket.off('checkUserPincodeLogin', handleCheckUserPincodeLogin);
      socket.off('registerUserPincodeLogin', handleRegisterUserPincodeLogin);
      socket.off('sendPincodeTerminal', handleSendPincodeTerminal);
      socket.off('sendPincodeSMS', handleSendPincodeSMS);
      socket.off(
        'smsOneTimeCodeConfirmIdentity',
        handleSmsOneTimeCodeConfirmIdentity
      );
    };
  }, [socket]);

  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const handleEditImage = async () => {
    updateIsFullUserEditing(true);
    updateIsNeedConfirmation(true);
    updateShowModalConfirmIdentity(false);
    updateIsLoginFromNfc(false);

    /*
    if (
      (!parentInfoRef.current &&
        !userInfoRef.current.username.startsWith('hybrid')) ||
      (parentInfo && !parentInfoRef.current.username.startsWith('hybrid'))
    ) {
      updateShowModalConfirmIdentity(false);
      updateShowModalPictureVerification({ status: true, isEdition: false });
    } else {
      updateIsFullUserEditing(true);
    }
    */
  };

  const cancel = async () => {
    updateFocusOnMainScreen(true);

    updateIsLimitSurpassed(false);
    updateIsDailyLimitSurpassed(false);
    updateIsMonthlyLimitSurpassed(false);

    logoutUser();
    updateShowUserInfo(false);
    updatePrint(false);
    resetTransactions();
    updateReceiptToPrint(false);
    updateIsCard(false);
    updateIsCash(false);
    updateTotal(0);
    updatePayCard(0);
    updatePayCash(0);
    updatePaySpillerkonto(0);
    updateChange(0);
    updateCurrentUser('');
    updateCurrentUserID('');
    updateUserLogin(false);
    updatePaymentType('');
    updateIsPaymentOpen(false);
    updateIsSellerOpen(false);
    logoutUser();
    updateCurrentUser('');
    updateCurrentUserID('');
    updateUserLogin(false);
    updateUserInfo({});
    updateIsFastUserLogin(false);
    updateIsAnonUser(false);
    updateIsHybridUser(false);
    updateIsFullUser(false);
    updateUserParent(false);
    updateUserChildren([]);
    updateUserSiblings([]);
    resetTicketsCH();
    updateShowModalConfirmIdentity(false);
    updateIsIdentityConfirmed(false);
  };

  const handleActivatePincode = () => {
    socket.emit('checkUserPincodeLogin', {
      bingoName: hallRef.current,
      userID: parentInfoRef.current
        ? parentInfoRef.current.id
        : userInfoRef.current.id,
      user7Pincode: parentInfoRef.current
        ? parentInfoRef.current['']
        : userInfoRef.current[''],
    });
    updateIsLoadingScreen(true);

    /* Pre 24h validation*/
    /*
    socket.emit('activateSMS', {
      bingoName: hallRef.current,
    });
    setIsWaiting(true);
    */
  };

  const handleCancelPincode = () => {
    socket.emit('deactivateSMS', {
      bingoName: hallRef.current,
    });
    setIsWaiting(false);
    setIsOneTimeCode(false);
  };

  const handlePostPone = () => {
    if (userInfoRef.current.username.startsWith('anon')) {
      updateShowUserInfo(true);
    }
    socket.emit('deactivateSMS', {
      bingoName: hallRef.current,
    });
    updateIsVerificationNeeded(false);
    updateIsLoginFromNfc(false);
    updateShowModalConfirmIdentity(false);
    updateIsIdentityConfirmed(true);
  };

  const handleSendSMS = () => {
    handleActivatePincodeOneTimeCode();
    return;
    /*
    if (smsSent) {
      return;
    }

    const smsData = {
      phonenumber: parentInfoRef.current
        ? parentInfoRef.current.mobile
        : userInfoRef.current.mobile,
      pincode: parentInfoRef.current
        ? parentInfoRef.current.PIN2
        : userInfoRef.current.PIN2,
      countryCode: parentInfoRef.current
        ? parentInfoRef.current.countryCode
        : userInfoRef.current.countryCode,
      sessionID: sessionRef.current,
    };

    socket.emit('sendPincodeSMS', smsData);
    setSmsSent(true);
    */
  };

  const handleActivatePincodeOneTimeCode = () => {
    setIsOneTimeCode(true);
    var dataToSend = {
      phonenumber: parentInfoRef.current
        ? parentInfoRef.current.mobile
        : userInfoRef.current.mobile,
      countryCode: parentInfoRef.current
        ? parentInfoRef.current.country_code
        : userInfoRef.current.country_code,
      sessionID: session,
      action: 'sms-hybrid',
    };
    socket.emit('smsOneTimeCodeConfirmIdentity', dataToSend);

    setIsWaiting(true);
    socket.emit('activateSMS', {
      bingoName: hallRef.current,
    });
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div
            className="react-confirm-alert-body"
            style={{
              width: isLoginFromNfc ? '1700px' : '1000px',
              height: isLoginFromNfc ? '1050px' : 'auto',
              display: isLoginFromNfc ? 'flex' : undefined,
              justifyContent: isLoginFromNfc ? 'center' : undefined,
              alignContent: isLoginFromNfc ? 'center' : undefined,
              alignItems: isLoginFromNfc ? 'center' : undefined,
              flexDirection: isLoginFromNfc ? 'column' : undefined,
            }}
          >
            {!isFirstCheck ? (
              <>
                {!isWaiting && !isSuccess && (
                  <>
                    <h1>{t('sales.photoVerificationTitle')} </h1>
                    <p>{t('sales.confirmLoginText')}</p>
                    {!isLoginFromNfc && (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            height: '600px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={userImageInfo.imageUrl}
                            alt={userImageInfo.blobName}
                            style={{
                              width: '100%',
                              height: '500px',
                              objectFit: 'contain',
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div
                      className="react-confirm-alert-button-group"
                      style={{ marginTop: '55px' }}
                    >
                      {!isLoginFromNfc && (
                        <button
                          className="btn-custom-yes"
                          onClick={() => {
                            handleEditImage();
                          }}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px', // Space between icon and text
                          }}
                        >
                          <PersonRounded style={{ fontSize: '1.2em' }} />

                          {t('sales.editUserConfirm')}
                        </button>
                      )}
                      {((!parentInfoRef.current &&
                        !userInfoRef.current.username.startsWith('hybrid')) ||
                        (parentInfo &&
                          !parentInfoRef.current.username.startsWith(
                            'hybrid'
                          ))) && (
                        <button
                          className="btn-custom-thirdOption"
                          onClick={() => {
                            handleActivatePincode();
                          }}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px', // Space between icon and text
                          }}
                        >
                          <Pin style={{ fontSize: '1.2em' }} />
                          {t('sales.confirmLoginPincode')}
                        </button>
                      )}

                      {!isLoginFromNfc && (
                        <button
                          className="btn-custom-postpone"
                          onClick={() => {
                            handlePostPone();
                          }}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px', // Space between icon and text
                          }}
                        >
                          <CheckCircleIcon style={{ fontSize: '1.2em' }} />
                          {t('sales.confirmLoginIKnowTheUser')}
                        </button>
                      )}

                      {isLoginFromNfc && (
                        /*
                    <button
                      className="btn-custom-postpone"
                      onClick={() => {
                        handleSendSMS();
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px', // Space between icon and text
                        opacity: smsSent ? 0.5 : 1,
                      }}
                    >
                      <Sms style={{ fontSize: '1.2em' }} />
                      <span style={{ fontSize: '0.7em' }}>
                        {t('sales.sendPincodeBySMS')}
                      </span>
                    </button>
                    */
                        <button
                          className="btn-custom-postpone"
                          onClick={() => {
                            handleSendSMS();
                          }}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px', // Space between icon and text
                            opacity: smsSent ? 0.5 : 1,
                          }}
                        >
                          <Sms style={{ fontSize: '1.2em' }} />
                          <span style={{ fontSize: '0.7em' }}>
                            {t('sales.sendOneTimeCodeBySMS')}
                          </span>
                        </button>
                      )}

                      <button
                        className="btn-custom-no"
                        onClick={() => {
                          cancel();
                        }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '8px', // Space between icon and text
                        }}
                      >
                        <CancelIcon style={{ fontSize: '1.2em' }} />
                        {t('sales.cancel')}
                      </button>
                    </div>
                  </>
                )}
                {isWaiting && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: '10px',
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        height: '100%',
                        width: '100%',
                        marginTop: 0,
                        marginLeft: 0,
                        justifyContent: 'center',
                        alignContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <h1
                          style={{
                            color: '#464646',
                          }}
                        >
                          {t('sales.pincodeTitle')}
                        </h1>
                        <p>{t('sales.pincodeText1')}</p>

                        <button
                          onClick={() => {
                            handleCancelPincode();
                          }}
                          className="btn-custom-no"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px', // Space between icon and text
                          }}
                        >
                          <CancelIcon style={{ fontSize: '1.2em' }} />

                          {t('sales.cancel')}
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                )}

                {isSuccess && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: '10px',
                    }}
                  >
                    {/*Header*/}
                    <Grid
                      container
                      sx={{
                        height: '100%',
                        width: '100%',
                        marginTop: 0,
                        marginLeft: 0,
                        justifyContent: 'center',
                        alignContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <h1
                          style={{
                            color: '#464646',
                          }}
                        >
                          {t('sales.newConfirmTitle')}
                        </h1>
                        <p> {t('sales.newConfirmText')}</p>

                        <button
                          onClick={async () => {
                            socket.emit('deactivateSMS', {
                              bingoName: hallRef.current,
                            });
                            setIsSuccess(false);
                            updateShowModalConfirmIdentity(false);

                            updateIsIdentityConfirmed(true);

                            //updateIsLoginFromNfc(false);
                          }}
                          className="btn-custom-yes"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px', // Space between icon and text
                          }}
                        >
                          <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                          {t('sales.confirm')}
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalPictureVerificationWarning;
